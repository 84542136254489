import React, { FC, JSX, ReactNode } from 'react';
import { datetimeToLocale } from '../../modules/Parse/Date';
import { HashMap } from '../../Samfe';


export type ListItem = {
    id?: string|number
    symbol?: ReactNode,
    title: string,
    description: string,
    feedback?: ReactNode,
    dateTime: string // @todo evaluate property name
}

export type ListItems = HashMap<string, ListItem[]>;

type Props = {
    items: ListItems
}

const OverviewList: FC<Props> = ({ items }): JSX.Element => {
    return <>
        <nav aria-label="Directory">
            { Object.keys(items).map((key) => (
                <div key={ key } className="relative top-10">

                    <div className="sticky -top-4 z-[2] border-y border-b-gray-200 border-t-gray-100 bg-white px-3  text-sm font-semibold leading-6 text-primary">
                        <h3 className={ 'relative -mx-7 px-4 py-1.5 bg-white' }>{ datetimeToLocale(key) }</h3>
                    </div>

                    <ul className="divide-y divide-gray-100">
                        { (items[key]).map((item, i) => (
                            <li key={ i } className="py-2 relative">
                                <div className={ 'absolute top-0 bottom-0 -left-4 -right-4 z-1 hover:bg-primary-accent hover:bg-opacity-5' }>&nbsp;</div>
                                <div className={ 'w-14 inline-block' }>{ item.symbol }</div>
                                <div className="inline-block w-[calc(100%-3.5rem)] align-top mt-0.5">
                                    <div className="grid grid-cols-12 gap-x-4 w-full">
                                        <div className="col-span-8">
                                            <p className="text-sm text-primary font-medium">{ item.title }</p>
                                            <p className="-mt-0.5 truncate text-xs leading-5 text-gray-500">{ item.description }</p>
                                        </div>
                                        <div className={ 'col-span-4' }>{ item.feedback }</div>
                                    </div>
                                </div>
                            </li>
                        )) }
                    </ul>

                </div>
            )) }
        </nav>
    </>;
};

export default OverviewList;