import React, { FC, JSX, useEffect } from 'react';
import useFormikField from '../../../modules/Form/useFormikField';
import Description from '../../Text/Description';
import ErrorMessage from './ErrorMessage';
import { BaseFieldProps } from './FieldTypes';
import Label, { ExtendLabelProps } from './Label';


export type FormControlProps = {
    label?: ExtendLabelProps,
    description?: {
        text?: string,
        inline?: boolean
    },
    errorMessage?: string
}

type Props = Omit<BaseFieldProps, 'fieldControl'>&{
    children: JSX.Element
    formControl: FormControlProps,
    value: string|number|boolean|undefined
}

const FieldControl: FC<Props> = ({
    children,
    name,
    value = '',
    formControl = {},
    withFormik
}): JSX.Element => {

    const { formikField, setFormikValue, formikError } = useFormikField(withFormik, name, value);

    useEffect(() => {
        setFormikValue(value);
    }, [ value ]);

    const { label, description, errorMessage } = formControl;
    return <div className={ 'mb-4 w-full' }>

        { label && <Label htmlFor={ name } { ...label } /> }
        { description && !description.inline && <Description className={ 'mb-2' }>{ description.text }</Description> }

        { children }

        { description && description.inline && <Description className={ 'inline-block align-top w-full max-w-[calc(100vw-5rem)]' }>{ description.text }</Description> }
        { (formikError || errorMessage) && <ErrorMessage message={ formikError ?? errorMessage }/> }

        <>{ withFormik && formikField }</>

    </div>;
};
export default FieldControl;