/* eslint-disable no-unused-vars */

import React, { JSX } from 'react';
import { Checkbox, Input, Select, Switch, Textarea } from '../../components/Form/Field';
import { FieldControlType } from '../../components/Form/Field/FieldTypes';
import { SelectConfig } from '../../components/Form/Field/Select';
import { VariablesType } from '../graphql/useGraphQL';


export type FormControlType = 'input'|'textArea'|'select'|'switch'|'checkbox';

export type FormControlProps<Model extends object, Input extends VariablesType> = {

    name: string,
    controlType: FormControlType,
    fieldConfig: FieldControlType,
    initialValue?: string|number|boolean,
    required?: boolean,
    disabled?: boolean,
    hidden?: boolean,
    onChange?: (value: any) => void
    type?: React.HTMLInputTypeAttribute,
    selectConfig?: SelectConfig<Model>,

    steps?: number,
    min?: number,
    max?: number,
}

const FormControl = <Model extends object, Dto extends Partial<Model>>({
    controlType,
    fieldConfig,
    selectConfig,
    initialValue,
    ...props
}: FormControlProps<Model, Dto>): JSX.Element => {

    switch (controlType) {

    case 'input':
        return <Input
            { ...props }
            fieldControl={ fieldConfig }
            type={ props.type ?? 'text' }
            withFormik={ true }
            value={ initialValue as string|number }
        />;

    case 'textArea':
        return <Textarea
            { ...props }
            fieldControl={ fieldConfig }
            withFormik={ true }
            value={ initialValue as string|number }
        />;

    case 'select':
        return <Select
            { ...props }
            fieldControl={ fieldConfig }
            withFormik={ true }
            selectConfig={ selectConfig! }
        />;

    case 'switch':
        return <Switch
            { ...props }
            fieldControl={ fieldConfig }
            withFormik={ true }
            value={ initialValue as boolean }
        />;

    case 'checkbox':
        return <Checkbox
            { ...props }
            fieldControl={ fieldConfig }
            withFormik={ true }
            value={ initialValue as boolean }
        />;

    default:
        return <span className={ 'text-red-700 font-bold' }>Invalid control provided!</span>;
    }
};
export default FormControl;
