import useGraphQL from '../../../lib/samfe/modules/graphql/useGraphQL';
import {
    CreateUserDocument,
    CreateUserMutation,
    CreateUserMutationVariables, DeleteUserDocument, DeleteUserMutation, DeleteUserMutationVariables,
    GetUserDocument, GetUserFormDataQuery, GetUserFormDataQueryVariables,
    GetUserQuery,
    GetUserQueryVariables, GetUsersDocument, GetUsersQuery,
    GetUsersQueryVariables, UpdateUserDocument, UpdateUserMutation, UpdateUserMutationVariables,
    UserModel
} from "../../../gql/schema";


const useUser = () => {
    const { querySingle, queryList, mutate, destroy } = useGraphQL<UserModel>();
    return {
        getUserFormData: querySingle<GetUserFormDataQueryVariables, GetUserFormDataQuery>(GetUserDocument, 'user'),
        getSingle: querySingle<GetUserQueryVariables, GetUserQuery>(GetUserDocument, 'user'),
        getList: queryList<GetUsersQueryVariables, GetUsersQuery>(GetUsersDocument, 'users'),
        create: mutate<CreateUserMutationVariables, CreateUserMutation>(CreateUserDocument, 'createUser'),
        update: mutate<UpdateUserMutationVariables, UpdateUserMutation>(UpdateUserDocument, 'updateUser'),
        destroy: destroy<DeleteUserMutationVariables, DeleteUserMutation>(DeleteUserDocument, 'deleteUser')
    };
};

export default useUser;