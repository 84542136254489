import { LinkOff } from '@mui/icons-material';
import React, {FC, JSX, useEffect, useState} from 'react';
import BasePage from '../../layout/BasePage';
import Accordeon from '../../lib/samfe/components/Accordeon/Accordeon';
import { ActionButton } from '../../lib/samfe/components/Button';
import ProgressBar from '../../lib/samfe/components/Feedback/ProgressBar';
import DetailTextImage from '../../lib/samfe/components/Image/DetailTextImage';
import H2 from '../../lib/samfe/components/Text/H2';
import H3 from '../../lib/samfe/components/Text/H3';
import {API_URL} from "../../config/variables";
import axios from "axios";
import {Link} from "react-router-dom";
import {useIdTokenCtx} from "../../lib/samfe/modules/Auth0/useIdToken";


type Props = {}

const TestOnboarding: FC<Props> = (): JSX.Element => {


    const current = 66.666;


    const idToken = useIdTokenCtx();
    const [authoriseUrl, setAuthoriseUrl] = useState<string>('/')
    useEffect(() => {
        axios.get<{authorize_url: string }>(`${API_URL}/mollie/authorize`, {
            headers: {
                'authorization': `Bearer ${idToken}`
            },
        }).then(res => setAuthoriseUrl(res.data.authorize_url))
    }, []);


    const progress = <>
        {idToken}
        <ProgressBar
            title={ 'Progress' }
            progressPercentage={ current }
            steps={ [
                // {
                //     identifier: 'step 1',
                //     percentage: 0,
                // },
                // {
                //     identifier: 'step 2',
                //     percentage: 33.333,
                // },
                // {
                //     identifier: 'step 3',
                //     percentage: 66.666,
                // },
                // {
                //     identifier: 'step 4',
                //     percentage: 100,
                // }
            ] }
        />
    </>;

    const steps = <>
        <Accordeon items={ [
            {
                title: <div>
                    <DetailTextImage abbreviation={ 'm' } className={ 'mr-4' }/>
                    <H3 className={ 'inline-block align-top pt-0.5' }>Connect Mollie</H3>
                </div>,
                content: <>
                    <div className={ 'px-[3.5rem] text-neutral pb-8' }>
                        Mollit laboris commodo ut cillum exercitation id velit enim ut nostrud. Dolor ex est non sint sunt consequat commodo.Velit amet pariatur aliquip non et aliquip sunt laboris. Aliqua duis
                    </div>

                    <Link to={authoriseUrl}><ActionButton className={ 'py-2 rounded-md' } Icon={ LinkOff }>Connect Mollie</ActionButton></Link>
                </>
            },
            {
                // title: 'Branding',
                title: <div>
                    <DetailTextImage abbreviation={ 'B' } className={ 'mr-4' }/>
                    <H3 className={ 'inline-block align-top pt-0.5' }>Branding</H3>
                </div>,
                content: 'lipsum'
            },
            {
                // title: 'Create your first subscription',
                title: <div>
                    <DetailTextImage abbreviation={ 'C' } className={ 'mr-4' }/>
                    <H3 className={ 'inline-block align-top pt-0.5' }>Create your first subscription</H3>
                </div>,
                content: 'lipsum'
            },
            {
                // title: 'Invite subscribers',
                title: <div>
                    <DetailTextImage abbreviation={ 'I' } className={ 'mr-4' }/>
                    <H3 className={ 'inline-block align-top pt-0.5' }>Invite subscribers</H3>
                </div>,
                content: 'lipsum'
            }
        ] }/>
    </>;


    return <BasePage title={ 'Merchant onboarding Test' } navbarTop={ {
        showBackButton: true
    } }>
        <H2>Connect Mollie</H2>
        { progress }
        <div className={ 'border border-secondary rounded-md mt-8' }>
            <div className="text-white bg-primary rounded-sm text-left py-4 font-bold px-4">Steps</div>
            <div className={ 'p-4' }>
                { steps }
            </div>

        </div>


    </BasePage>;
};
export default TestOnboarding;