import { Edit } from '@mui/icons-material';
import React, { FC, JSX } from 'react';
import Button, { ButtonProps } from './Button';


/**
 *
 * @param props
 * @constructor
 */
const EditButton: FC<ButtonProps> = (props): JSX.Element => (
    <Button { ...props } textColor={ 'white' } bgColor={ 'secondary' } Icon={ Edit }>
        Edit
    </Button>
);

export default EditButton;
