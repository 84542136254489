import React, { FC, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseCompanyFragment, CreateCompanyInput, CreateCompanyMutationVariables } from '../../../gql/schema';
import BasePage from '../../../layout/BasePage';
import Form, { useForm } from '../../../lib/samfe/modules/Form';
import useSchema from '../../../lib/samfe/modules/Form/useSchema';
import Yup from '../../../lib/samfe/modules/Form/Yup';
import useCompany from '../../../resources/Company/useCompany';


const CompanyFormCreateTest: FC = (): JSX.Element => {

    const navigate = useNavigate();
    const company = useCompany();

    const { validationSchema } = useSchema<CreateCompanyInput>({
        name: Yup.string()
            .controlType('input')
            .fieldConfig({
                label: 'Naam'
            })
            .inputType('text')
            .required()
    });

    const form = useForm<
        CreateCompanyInput,
        CreateCompanyMutationVariables,
        BaseCompanyFragment
    >({
        config: {},
        http: {
            formAction: company.create,
            method: 'POST',
            onSuccess: () => {
                navigate('/testing/company-list');
            }
        },
        validationSchema

    });


    return <BasePage title={ 'Company Create Test Form' } navbarTop={ {
        showBackButton: true
    } }>
        <Form { ...form } withSaveButton={ true }/>
    </BasePage>;
};
export default CompanyFormCreateTest;