import React, {useState} from 'react';
import FormComponent from "../Components/FormComponent";
import RegisterPageHeaderComponent from "../Components/RegisterPageHeaderComponent";

export interface Profile {
    email: string;

    firstName: string;

    lastName: string;

    phone: string
}

const initialState: Profile = {
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
};

const label = ['Email', 'First name', 'Last name', 'Phone']

const CreateProfileScreen: React.FC = () => {
    const [profile, setProfile] = useState(initialState);

    const keys = Object.keys(profile);

    function handleSubmit(
        e: React.FormEvent<HTMLFormElement | HTMLButtonElement>
    ) {

        e.preventDefault();
        setProfile(initialState);

    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setProfile((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            };
        });
    }

    return (
        <div className='flex flex-col px-2.5 pt-10'>
            <RegisterPageHeaderComponent title={'Create Profile'}/>
            <FormComponent keys={keys} handleSubmit={handleSubmit} handleChange={handleChange} label={label}
                value={profile}/>
        </div>
    );
};
export default CreateProfileScreen;
