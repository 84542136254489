import React, {ReactNode} from 'react'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip"
import {TooltipArrow} from "@radix-ui/react-tooltip";


interface PricingTooltipComponentProps {
    children: ReactNode
}

const PricingTooltipComponent: React.FC<PricingTooltipComponentProps> = ({children}) => {
    return <TooltipProvider>
        <Tooltip defaultOpen={true} open={true}>
            <TooltipTrigger asChild>{children}</TooltipTrigger>
            <TooltipContent className={'text-white bg-black'}>
                20% Off
                <TooltipArrow className="TooltipArrow"/>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
}

export default PricingTooltipComponent