import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Identity, UserModel } from '../gql/schema';
import BasePage from '../layout/BasePage';
import useIdentity from '../resources/auth/Identity/useIdentity';
import useUser from '../resources/auth/User/useUser';
import { Tjck207 } from '../tjck207';


const Home = () => {

    const { user } = useAuth0();
    const identityGql = useIdentity();
    const userGql = useUser();
    const [ identity, setIdentity ] = useState<Identity>();
    const [ gqlUser, setGqlUser ] = useState<UserModel>();

    useEffect(() => {
        identityGql.getSingle({}).then(res => {
            setIdentity(res)
            if (res?.user?.id) {
                userGql.getSingle({id: res?.user?.id}).then(setGqlUser);
            }
        });
    }, []);

    useEffect(() => {
        console.log({ gqlUser });
    }, [ gqlUser ]);

    useEffect(() => {
        console.log({ identity });
    }, [ identity ]);


    return <BasePage title={ 'Home' } navbarTop={ {
        showBackButton: true
    } }>
        <div className="grid gap-8 grid-cols-1">
            <div className={ 'shadow-md rounded p-4' }>
                <div className="grid gap-2 grid-cols-2">
                    <div>
                        <img src={ user?.picture } alt={ user?.name }/>
                    </div>
                    <div>
                        <h2>{ user?.name }</h2>
                        <p className={ 'text-sm' }>{ user?.email }</p>
                    </div>
                </div>
            </div>

            { identity?.user.name }
            <div className={ 'shadow-md rounded p-8' }>

                <Tjck207/>

                <h2 className={ 'text-lg font-medium' }>Routes</h2>
                <ul className={ 'list-disc list-inside text-primary-400 underline' }>
                    <li><Link to={ 'two-factor' }>Two factor</Link></li>
                    <li><Link to={ 'terms-and-conditions' }>Terms</Link></li>
                    <li><Link to={ 'styling' }>Styling</Link></li>
                    <li><Link to={ 'testing' }>Testing</Link></li>
                </ul>
            </div>
        </div>
    </BasePage>;

};

export default Home;