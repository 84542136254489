import React, { FC, JSX, ReactElement, useEffect, useState } from 'react';
import { SortByDate, sqlDateTimeToDate } from '../../modules/Parse/Date';
import { round, toEuroString } from '../../modules/Parse/Number';
import { SortObjectKeys } from '../../modules/Parse/Object';
import DetailImage from '../Image/DetailImage';
import OverviewList, { ListItem, ListItems } from './OverviewList';


type PaymentState = 'received'|'failed'|'refunded'|'reversed';

export type PaymentItem = {
    id?: string|number
    image: string,
    name: string,
    amount: number,
    state: PaymentState,
    date: string
}


type Props = {
    items: PaymentItem[]
}
const PaymentList: FC<Props> = ({ items }): JSX.Element => {

    /**
     * Format display amount so that cents are shown as "super"
     * @param amount
     */
    const formatAmount = (amount: number) => {
        const cents = round(amount % 1, 2);
        const displayCents = cents>0 ?cents * 100 :'00';
        const whole = amount - cents;
        const wholeDisplay = toEuroString(whole).split(',')[0];
        return <>{ wholeDisplay },<sup className={ 'relative -top-[0.18rem]' }>{ displayCents }</sup></>;
    };

    const receivedItem = (amount: number) => <span className={ 'text-secondary' }>{ formatAmount(amount) }</span>;
    const failedItem = (amount: number) => <span className={ 'text-warning line-through' }>{ formatAmount(amount) }</span>;
    const refundedItem = (amount: number) => <span className={ 'text-danger' }>{ formatAmount(-amount) }</span>;
    const reversedItem = (amount: number) => <span className={ 'text-danger' }>{ formatAmount(-amount) }</span>;

    /**
     *
     * @param amount
     * @param paymentState
     */
    const parsePaymentItem = (amount: number, paymentState: PaymentState) => {
        let parsedAmount: ReactElement<HTMLSpanElement> = <span>-</span>;
        let description: string = 'Unknown status';

        if (paymentState === 'received') {
            parsedAmount = receivedItem(amount);
            description = 'Payment received';
        } else if (paymentState === 'failed') {
            parsedAmount = failedItem(amount);
            description = 'Payment failed';
        } else if (paymentState === 'refunded') {
            parsedAmount = refundedItem(amount);
            description = 'Payment refunded';
        } else if (paymentState === 'reversed') {
            parsedAmount = reversedItem(amount);
            description = 'Payment reversed';
        }
        return { parsedAmount, description };
    };

    /**
     *
     */
    const parseListItems = (): ListItems => {
        const listItems: ListItems = {};
        [ ...items ].forEach(item => {

            const parsedItem = parsePaymentItem(item.amount, item.state);
            const symbolItem = <DetailImage src={ item.image }/>;
            const feedbackItem = <span className={ 'text-right block mt-2 font-medium' }>{ parsedItem.parsedAmount }</span>;

            const listItem: ListItem = {
                title: item.name,
                description: parsedItem.description,
                symbol: symbolItem,
                feedback: feedbackItem,
                dateTime: item.date
            };

            const date: string = sqlDateTimeToDate(item.date);

            // Initialize array if date is never used.
            if (!Array.isArray(listItems[date])) {
                listItems[date] = [];
            }
            listItems[date].push(listItem);
        });

        // Sort array items per list item.
        Object.keys(listItems).forEach(key => {
            listItems[key].sort((a, b) => SortByDate(a.dateTime, b.dateTime, 'ASC'));
        });

        // sort list items by key
        return SortObjectKeys(listItems, 'date', 'DESC');
    };


    const [ listItems, setListItems ] = useState(parseListItems());
    useEffect(() => {
        setListItems(parseListItems());
    }, [ items ]);

    return <OverviewList items={ listItems }/>;
};
export default PaymentList;