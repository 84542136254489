import { Delete } from '@mui/icons-material';
import React, { FC, JSX } from 'react';
import Button, { ButtonProps } from './Button';


/**
 *
 * @param props
 * @constructor
 */
const DeleteButton: FC<ButtonProps> = (props): JSX.Element => (
    <Button { ...props } textColor={ 'white' } bgColor={ 'danger' } Icon={ Delete }>
        Delete
    </Button>
);

export default DeleteButton;