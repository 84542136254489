import React from 'react'
import {z} from "zod";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";
import CheckOutFormComponent from "../Components/CheckOutComponent/CheckOutFormComponent";


export const formSchema = z.object({
    paymentMethod: z.enum(['SEPA', 'Credit Card', 'PayPal']),
    cardNumber: z.string().min(1, {
        message: 'Card number must be at least 1 character.'
    }),
    cardHolder: z.string().min(1, {
        message: 'Card holder must be at least 1 character.'
    }),
    expirationDate: z.string().min(1, {}),
    cvv: z.string().min(3),
    agreeToTerms: z.boolean()
})

export const labels = ['Payment Method', 'Card number', 'Card Holder', 'Expiration Date', 'CVV'];
export const paymentMethods = ['SEPA', 'Credit Card', 'PayPal'];

const CheckOutPage: React.FC = () => {

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            paymentMethod: 'SEPA',
            cardNumber: '',
            cardHolder: '',
            expirationDate: '',
            cvv: '',
            agreeToTerms: false,
        }
    })

    function handleOnSubmit(values: z.infer<typeof formSchema>) {

        console.log({values})

    }

    return (
        <div className={'flex flex-col items-center px-2.5'}>
            <div className={'text-3xl font-semibold mt-10'}>
                <p>
                    CheckOut
                </p>
            </div>
            <div className={'flex-col flex w-full items-center my-8'}>
                <p className={'text-xl font-medium'}>Total</p>
                <p className={'text-5xl font-semibold'}>$19.95</p>
            </div>
            <CheckOutFormComponent form={form} labels={labels} paymentMethods={paymentMethods} buttonText={'Checkout'}
                handleOnSubmit={handleOnSubmit}/>
        </div>
    )
}

export default CheckOutPage