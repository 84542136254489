import React from 'react'
import {Button} from "@/components/ui/button";
import {Lock} from "@mui/icons-material";

const SEPAFormComponent: React.FC = () => {

    return (
        <div>
            <Button type={'button'} className={'text-white w-full gap-x-2 py-5'}>
                <span className={'text-xl'}>
                Authorize SEPA
                </span>
                <Lock/>
            </Button>
        </div>
    )
}
export default SEPAFormComponent