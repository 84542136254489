import React, { ChangeEvent, FC, JSX, useState } from 'react';
import { classNames } from '../../../modules/Parse/String';
import FieldControl from './FieldControl';
import { BaseFieldProps } from './FieldTypes';


type Props = Omit<JSX.IntrinsicElements['input'], 'type'|'value'|'checked'>&BaseFieldProps&{
    value?: boolean
}

const Checkbox: FC<Props> = ({
    className,
    fieldControl,
    name,
    value,
    onChange,
    withFormik,
    ...props
}): JSX.Element => {

    const [ checkboxState, setCheckboxState ] = useState(value == true ?1 :0);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCheckboxState(e.target.checked ?1 :0);
        onChange && onChange(e);
    };

    return <FieldControl
        name={ name }
        value={ checkboxState }
        withFormik={ withFormik }
        formControl={ {
            ...fieldControl,
            label: {
                name: fieldControl?.label
            },
            description: {
                text: fieldControl?.description,
                inline: true
            }
        } }
    >
        <input
            { ...props }
            id={ name }
            type={ 'checkbox' }
            value={ checkboxState }
            checked={ checkboxState == 1 }
            onChange={ handleChange }
            className={ classNames('inline-block align-top w-4 h-4 mr-2 bg-primary-100 accent-primary', className) }
        />
    </FieldControl>;
};
export default Checkbox;