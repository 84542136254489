import { SvgIconComponent } from '@mui/icons-material';
import React, { JSX } from 'react';
import { classNames } from '../../modules/Parse/String';
import { Color } from '../../utils/Colors/Colors';


type ButtonState = 'primary'|'secondary'|'tertiary'


export type BaseButtonProps = JSX.IntrinsicElements['button']&{
    state?: ButtonState
    small?: boolean|(() => boolean)
}


export type ButtonProps = Omit<BaseButtonProps, 'children'>


type Props = BaseButtonProps&{
    bgColor: Color
    textColor: Color,
    Icon: SvgIconComponent
};


/**
 * Base button configuration.
 */
const Button: React.FC<Props> = ({
    state = 'primary',
    bgColor,
    textColor,
    disabled,
    Icon,
    small,
    children,
    ...props
}): JSX.Element => {

    const getButtonColorClassNames = (): string => {

        const base = 'w-full rounded mx-auto text-sm';
        const shadow = 'shadow shadow-sm transition-shadow hover:shadow-md active:shadow-none';
        const disabledClassNames = '!cursor-not-allowed !hover:shadow-none';
        const focus = `focus:outline-none focus-visible:ring focus-visible:ring focus-visible:ring-offset-2 focus-visible:ring-1 focus-visible:ring-${ bgColor }-focus`;
        const smallClasses = small && 'w-8 h-7 !p-0 !pb-1 !text-[1rem]';

        const primary = classNames(base, focus, smallClasses,
            'py-1 font-medium',
            `bg-${ bgColor } text-${ textColor }`,
            `hover:bg-${ bgColor }-hover`,
            `active:bg-${ bgColor }-active`,
            disabled ?`!bg-gray-400 !hover:bg-gray-400 !active:bg-gray-400 !text-white ${ disabledClassNames }` :shadow
        );

        if (state === 'secondary') {
            return classNames(base, focus, smallClasses,
                'bg-transparent border py-0.5 font-light',
                `text-${ bgColor } border-${ bgColor }`,
                `hover:text-${ bgColor }-hover hover:border-${ bgColor }-hover`,
                `active:text-${ bgColor }-active active:border-${ bgColor }-active`,
                disabled ?`!border-gray-400 !hover:border-gray-400 !active:border-gray-400 !text-gray-400 ${ disabledClassNames }` :shadow
            );

        } else if (state === 'tertiary') {
            return classNames(base, focus, smallClasses,
                'py-1 decoration-[0.1rem] underline underline-offset-4 font-medium',
                `text-${ bgColor } decoration-${ bgColor }`,
                `hover:text-${ bgColor }-hover hover:decoration-${ bgColor }-hover`,
                `active:text-${ bgColor }-active active:decoration-${ bgColor }-active`,
                disabled && `!text-gray-400 !hover:text-gray-400 !active:text-gray-400 ${ disabledClassNames }`
            );
        }
        return primary;
    };

    return <button
        { ...props }
        { ...!disabled && { onClick: props.onClick } }
        className={ classNames(getButtonColorClassNames(), props.className) }
    >{ small ?<Icon className={ '!text-[1rem]' }/> :children }</button>;

};
export default Button;
