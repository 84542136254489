import React, {useState} from 'react';
import FormComponent from "../Components/FormComponent";
import RegisterPageHeaderComponent from "../Components/RegisterPageHeaderComponent";

export interface Company {
    companyName: string;
    vatNumber: string;
    cocNumber: string;
    address: string;
    zipCode: string;
    city: string;
}

const initialState = {
    companyName: '',
    vatNumber: '',
    cocNumber: '',
    address: '',
    zipCode: '',
    city: '',
};

const label = ['Company Name', 'VAT Number', 'CoC Number', 'Address', 'Zip Code', 'City']

const CreateCompanyScreen: React.FC = () => {
    const [company, setCompany] = useState(initialState);

    const keys = Object.keys(company);

    function handleSubmit(
        e: React.FormEvent<HTMLFormElement | HTMLButtonElement>
    ) {
        e.preventDefault();
        setCompany(initialState);
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setCompany((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            };
        });
    }

    return (
        <div className='flex flex-col px-2.5 pt-10'>
            <RegisterPageHeaderComponent title={'Create Company'}/>
            <FormComponent keys={keys} handleSubmit={handleSubmit} handleChange={handleChange} label={label}
                value={company}/>
        </div>
    );
};

export default CreateCompanyScreen;
