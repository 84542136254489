// eslint-disable-next-line no-unused-vars
import { DocumentNode } from 'graphql';
import { GraphQLClient } from 'graphql-request';
import { API_URL } from '../../../../config/variables';
import { BaseModel, Exact } from '../../../../gql/schema';
import { useIdTokenCtx } from '../Auth0/useIdToken';


export type VariablesType = Exact<{ [key: string]: any; }>
export type ExtendBaseModel = Partial<Omit<BaseModel, '__typename'>>&{ __typename?: string };

/**
 * @see https://www.npmjs.com/package/graphql-request
 * @see https://www.jsdocs.io/package/graphql-request
 * @see https://github.com/jasonkuhrt/graphql-request
 */
const useGraphQL = <Model extends ExtendBaseModel>() => {

    type TypeOfReturn = Model|undefined|Model[]|boolean;

    /**
     *
     */
    const idToken = useIdTokenCtx();


    /**
     *
     */
    const graphQLClient = new GraphQLClient(`${ API_URL }/query`, {
        headers: {
            authorization: `Bearer ${ idToken }`
        }
    });

    const execute = async <Variables extends VariablesType, QueryType extends object>(
        document: DocumentNode,
        variables: Variables,
        modelKey: string,
        isList: boolean = false
    ): Promise<QueryType> => {
        if (!idToken) {
            return {
                [modelKey]: (isList ?[] :undefined)
            } as QueryType;
        }
        return graphQLClient?.request<QueryType>(document, variables);
    };


    const prepare = <
        Variables extends VariablesType,
        QueryType extends object,
        ReturnType extends TypeOfReturn
    >
        (
            document: DocumentNode,
            modelKey: keyof QueryType,
            isList: boolean = false
        ) => (variables: Variables): Promise<ReturnType> => (
            execute<Variables, QueryType>(document, variables, modelKey as string, isList)
                .then(res => res[modelKey] as ReturnType)
        );


    const querySingle = <Variables extends VariablesType, QueryType extends object>(document: DocumentNode, modelKey: keyof QueryType) => {
        return prepare<Variables, QueryType, Model|undefined>(document, modelKey);
    };

    const queryList = <Variables extends VariablesType, QueryType extends object>(document: DocumentNode, modelKey: keyof QueryType) => {
        return prepare<Variables, QueryType, Model[]>(document, modelKey, true);
    };

    const mutate = <Variables extends VariablesType, QueryType extends object>(document: DocumentNode, modelKey: keyof QueryType) => {
        return prepare<Variables, QueryType, Model|undefined>(document, modelKey);
    };

    const destroy = <Variables extends VariablesType, QueryType extends object>(document: DocumentNode, modelKey: keyof QueryType) => {
        return prepare<Variables, QueryType, boolean>(document, modelKey);
    };


    return {
        execute,
        prepare,
        querySingle,
        queryList,
        mutate,
        destroy
    };
};

export default useGraphQL;