import React, { FC, JSX, useEffect, useState } from 'react';
import { classNames } from '../../../modules/Parse/String';
import FieldControl from './FieldControl';
import { BaseFieldProps } from './FieldTypes';


type Props = JSX.IntrinsicElements['textarea']&BaseFieldProps&{
    value?: string|number
}

const Textarea: FC<Props> = ({
    className,
    fieldControl,
    name,
    withFormik,
    ...props
}): JSX.Element => {

    const [ value, setValue ] = useState(props.value);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setValue(e.target.value);
        props.onChange && props.onChange(e);
    };

    useEffect(() => {
        setValue(value);
    }, [ props.value ]);

    return <FieldControl
        name={ name }
        value={ value }
        withFormik={ withFormik }
        formControl={ {
            ...fieldControl,
            label: {
                name: fieldControl?.label
            },
            description: {
                text: fieldControl?.description
            }
        } }
    >
        <textarea { ...props } value={ value } onChange={ handleChange } id={ name } className={ classNames('w-full py-2 font-[400] px-4 bg-primary-100 text-primary rounded', className) }/>
    </FieldControl>;
};
export default Textarea;