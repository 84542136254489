import React, { FC, JSX } from 'react';
import BasePage from '../../layout/BasePage';
import { Checkbox, Input, Select, Switch, Textarea } from '../../lib/samfe/components/Form/Field';


const FormFields: FC = (): JSX.Element => {

    return <BasePage title={ 'Form fields' } navbarTop={ {
        showBackButton: true
    } }>

        <Input
            type={ 'text' }
            name={ 'foo' }
            placeholder={ 'Input placeholder' }
            fieldControl={ {
                label: 'Switch',
                description: 'Description of this input',
                errorMessage: 'Veld is verplicht'
            } }
        />

        <Select
            name={ 'foo' }
            fieldControl={ {
                label: 'Select'
            } }
            selectConfig={ {
                displayCol: 'displayName',
                valueCol: 'value',
                models: [
                    {
                        displayName: 'Sam',
                        value: 1
                    },
                    {
                        displayName: 'Bram',
                        value: 2
                    }
                ]
            } }
        />

        <Select
            name={ 'foo' }
            fieldControl={ {
                label: 'Select',
                description: 'With initial model'
            } }
            selectConfig={ {
                displayCol: 'displayName',
                valueCol: 'value',
                initialModel: {
                    displayName: 'Bram',
                    value: 2
                },
                models: [
                    {
                        displayName: 'Sam',
                        value: 1
                    },
                    {
                        displayName: 'Bram',
                        value: 2
                    }
                ]
            } }
        />

        <Textarea
            name={ 'foo' }
            placeholder={ 'Textarea placeholder' }
            fieldControl={ {
                label: 'Textarea'
            } }
        />

        <Textarea
            name={ 'foo' }
            value={ 'test' }
            fieldControl={ {
                label: 'Textarea',
                description: 'With initial value'
            } }
        />

        <Switch
            name={ 'foo' }
            fieldControl={ {
                label: 'Switch'
            } }
        />

        <Switch
            name={ 'foo' }
            value={ true }
            fieldControl={ {
                label: 'Switch',
                description: 'With initial value'
            } }
        />

        <Checkbox
            name={ 'foo' }
            placeholder={ 'Foo placeholder' }
            fieldControl={ {
                label: 'Checkbox',
                description: 'Description of this checkbox should not go under checkbox if text is too long as this example text. So we write some more lorem ipsum and bs like that.'
            } }
        />

        <Checkbox
            name={ 'foo' }
            value={ true }
            placeholder={ 'Foo placeholder' }
            fieldControl={ {
                description: 'With initial value "true".'
            } }
        />

    </BasePage>;

};
export default FormFields;