import { Save } from '@mui/icons-material';
import React, { FC, JSX } from 'react';
import Button, { ButtonProps } from './Button';


/**
 *
 * @param props
 * @constructor
 */
const SaveButton: FC<ButtonProps> = (props): JSX.Element => (
    <Button{ ...props } textColor={ 'white' } bgColor={ 'success' } Icon={ Save }>
        Save
    </Button>
);

export default SaveButton;
