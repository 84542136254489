import React, { FC, JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateUserInput, UpdateUserMutationVariables, UserModel } from '../../../gql/schema';
import BasePage from '../../../layout/BasePage';
import Form, { useForm } from '../../../lib/samfe/modules/Form';
import useSchema from '../../../lib/samfe/modules/Form/useSchema';
import Yup from '../../../lib/samfe/modules/Form/Yup';
import useUser from '../../../resources/auth/User/useUser';


const UserFormUpdateTest: FC = (): JSX.Element => {

    const navigate = useNavigate();
    const { id } = useParams();
    const user = useUser();

    const { validationSchema } = useSchema<UpdateUserInput>({
        // @fixme cannot edit on update, only last keystroke is set + initial
        name: Yup.string()
            .controlType('input')
            .fieldConfig({
                label: 'Naam'
            })
            .inputType('text')
            .required()
    });

    const form = useForm<
        UpdateUserInput,
        UpdateUserMutationVariables,
        UserModel
    >({
        config: {
            id
        },
        http: {
            initialModel: () => user.getUserFormData({ id: id! }),
            formAction: user.update,
            method: 'PUT',
            onSuccess: () => {
                navigate('/testing/user-list');
            }
        },
        validationSchema
    });


    return <BasePage title={ 'User Update Test Form' } navbarTop={ {
        showBackButton: true
    } }>
        <Form { ...form } withSaveButton={ true }/>
    </BasePage>;
};
export default UserFormUpdateTest;