/**
 *
 * @param value
 * @param precision
 */
export const round = (value: number|undefined, precision: number): number => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round((value ?? 0) * multiplier) / multiplier;
};


/**
 *
 * @param value
 */
export const thousandSeparator = (value?: number|string): string => `${ value ?? 0 }`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');


/**
 *
 * @param number
 * @param precision
 */
export const toPercentageString = (number: number|undefined, precision: number = 2): string => thousandSeparator(
    `${ round(number, precision) }%`.replace('.', ',')
);


/**
 * Parse amount to Euro string.
 *
 * @param amount
 * @constructor
 */
export const toEuroString = (amount?: number|string): string => thousandSeparator(
    new Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
    }).format(typeof amount === 'string'
        ?parseFloat(amount ?? '0.00')
        :round(amount, 2) ?? 0.00
    )
);