import React, {useState} from 'react';
import PricingTabComponent from "../Components/SubscriptionComponents/PricingTabComponent";
import PricingCardComponent from "../Components/SubscriptionComponents/PricingCardComponent";
import PricingPeriodTabComponent from "../Components/SubscriptionComponents/PricingPeriodTabComponent";
import SubscriptionHeaderComponent from "../Components/SubscriptionComponents/SubscriptionHeaderComponent";

import {register} from 'swiper/element/bundle';

register();

export interface FakeDataInt {
    label: string;
    price: number;
}

export const pricingLabel = ['Start Up', 'Scale Up', 'Pro'];
const pricingPeriod = ['per month', 'per year']
const fakeData: FakeDataInt[] = [
    {
        label: 'Start Up',
        price: 14.99
    },
    {
        label: 'Scale Up',
        price: 19.99
    },
    {
        label: 'Pro',
        price: 24.99
    }];
const SubscriptionPage: React.FC = () => {
    const [tab, setTab] = useState('Scale Up');
    const [currentPricingPeriod, setCurrentPricingPeriod] = useState('per month')


    function handleTabChange(value: string) {
        setTab(value);
    }

    return (
        <div className='flex flex-col items-center'>
            <SubscriptionHeaderComponent title={'Choose a Plan'}/>
            <PricingTabComponent handleClick={handleTabChange} value={pricingLabel} currentTab={tab}
                defaultValue={'Scale' +
                                     ' Up'}/>
            <PricingPeriodTabComponent period={pricingPeriod} currentPricingPeriod={currentPricingPeriod}
                setCurrentPricingPeriod={setCurrentPricingPeriod}/>
            <PricingCardComponent data={fakeData} tab={tab} setTab={setTab}
                currentPricingPeriod={currentPricingPeriod}/>
        </div>
    );
};

export default SubscriptionPage;
