import {Alert, AlertDescription, AlertTitle} from '@/components/ui/alert';
import {Terminal} from '@mui/icons-material';
import React from 'react';


export const Tjck207 = () => {
    return (
        <div className={'py-8'}>
            <Alert>
                <Terminal className="h-4 w-4"/>
                <AlertTitle>Heads up!</AlertTitle>
                <AlertDescription>
                    ShadCN is installed in branch feature/TJCK-207
                </AlertDescription>
            </Alert>
        </div>
    );
};
