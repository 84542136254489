import { Auth0Provider } from '@auth0/auth0-react';
import React, { FC, JSX } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './config/reportWebVitals';
import { IS_PRODUCTION } from './config/variables';
import { IdTokenProvider } from './lib/samfe/modules/Auth0/useIdToken';


const AppRoot: FC = (): JSX.Element => <>
    <Auth0Provider
        domain="samfe.eu.auth0.com"
        clientId="SwwaGgL8huCiQjXMvc3OW5cA6GelYex7"
        authorizationParams={ { redirect_uri: window.location.origin } }
        cacheLocation={ 'localstorage' }
    >
        {/* @todo secure cookie policy */ }
        <CookiesProvider defaultSetOptions={ { path: '/' } }>
            <IdTokenProvider>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </IdTokenProvider>
        </CookiesProvider>
    </Auth0Provider>
</>;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(IS_PRODUCTION ?<React.StrictMode><AppRoot/></React.StrictMode> :<AppRoot/>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
