import { Cancel } from '@mui/icons-material';
import React, { FC, JSX } from 'react';
import Button, { ButtonProps } from './Button';


/**
 *
 * @param props
 * @constructor
 */
const CancelButton: FC<ButtonProps> = (props): JSX.Element => (
    <Button { ...props } textColor={ 'white' } bgColor={ 'primary' } Icon={ Cancel }>
        Cancel
    </Button>
);
export default CancelButton;