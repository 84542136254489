import { useAuth0 } from '@auth0/auth0-react';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginButton from '../../lib/samfe/components/Button/LoginButton';
import './login.css';


const Login = () => {

    const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoading) {return;}
        if (isAuthenticated) {navigate('/');}
    }, [ isLoading ]);


    return <>{ !isLoading &&
        <div className={ 'fade-in text-center' }>
            <header>
                <motion.div
                    animate={ {
                        y: 75
                    } }
                >
                    <h1 className="text-2xl font-bold mt-20">Sign in</h1>
                    <p>Welcome back!</p>
                </motion.div>
                <motion.div
                    animate={ {
                        y: -75
                    } }
                >
                    <span className="block text-4xl mt-8 tjecko-logo text-neutral">TJ€CKO</span>

                </motion.div>

            </header>
            <main>
                <LoginButton onClick={ () => loginWithRedirect() }/>
            </main>
        </div> }
    </>;
};

export default Login;
