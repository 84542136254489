import { Disclosure } from '@headlessui/react';
import React, { FC, isValidElement, JSX, ReactNode } from 'react';
import H3 from '../Text/H3';


type Item = {
    title: ReactNode,
    content: ReactNode
}
type Props = {
    items: Item[]
}

const Accordeon: FC<Props> = ({ items }): JSX.Element => {


    return <>
        { items.map((item, i) => <Disclosure key={ i } as={ 'div' } className={ 'mb-2 px-3 py-3 border border-primary rounded-md border-opacity-20 focus:outline-none focus-visible:ring focus-visible:ring-primary focus-visible:ring-opacity-75' }>
            { () => (
                <>
                    <div className={ '-mb-1.5' }>
                        <Disclosure.Button className="w-full text-left text-primary">
                            { isValidElement(item.title) ?item.title :<H3>{ item.title }</H3> }
                        </Disclosure.Button>
                    </div>
                    <Disclosure.Panel className="text-sm text-gray-500">
                        { item.content }
                    </Disclosure.Panel>
                </>
            ) }
        </Disclosure>) }
    </>;
};
export default Accordeon;

