import React, { FC, JSX, ReactElement } from 'react';
import { classNames } from '../../modules/Parse/String';


type Step = {
    percentage: number,
    identifier?: number|string,
    // @todo onClick
    // @todo disable
}
type Props = {
    title?: string
    progressPercentage: number
    progressIdentifier?: boolean
    steps?: Step[]
}

const ProgressBar: FC<Props> = ({ title, progressPercentage, progressIdentifier = true, steps = [] }): JSX.Element => {

    /**
     *
     * @param percentage
     */
    const stepIndicator = (percentage: number): ReactElement<HTMLButtonElement> => <button key={ percentage } type={ 'button' } style={ { left: `${ percentage }%` } }
        className={ classNames('absolute w-4 h-4 rounded-full top-[1.5rem]',
            percentage>0 && percentage<90 && ' -translate-x-1/2',
            percentage>=90 && ' -translate-x-full',
            percentage<progressPercentage && 'bg-primary ring-[2px] ring-white border border-primary',
            percentage == progressPercentage && 'bg-white ring-[2px] ring-white border border-primary',
            percentage>progressPercentage && 'bg-white border border-primary'
        ) }
    >
        { percentage == progressPercentage && <div className="w-[0.44rem] h-[0.44rem] bg-primary rounded-full translate-x-1/2"></div> }
    </button>;

    return <>
        <div>
            <h4 className="sr-only">Status</h4>
            <div className="pt-7 relative" aria-hidden="true">

                { title && <p className="text-sm font-medium text-primary absolute left-0 top-0">{ title }</p> }
                { progressIdentifier && <div className="text-primary text-sm absolute top-0 right-0">{ progressPercentage.toFixed(1) }%</div> }

                { steps.map(step => stepIndicator(step.percentage)) }

                <div className="overflow-hidden rounded-full bg-primary-accent bg-opacity-20">
                    <div className="h-2 rounded-full bg-primary" style={ { width: `${ progressPercentage }%` } }/>
                </div>

                <div className="mt-2 text-sm font-medium text-gray-600 relative w-full">
                    { steps.map(step => <button key={ step.identifier } style={ { left: `${ step.percentage }%` } } className={ classNames(
                        'absolute break-keep whitespace-nowrap',
                        step.percentage>10 && step.percentage<90 && '-translate-x-1/2',
                        step.percentage>=90 && 'text-right -translate-x-full'
                    ) }>
                        { step.identifier }
                    </button>) }
                </div>

            </div>
        </div>
    </>;
};
export default ProgressBar;