import React, {useRef, useEffect, Dispatch, SetStateAction} from "react";

import {Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle} from "@/components/ui/card";
import {Button} from "@/components/ui/button";
import {FakeDataInt, pricingLabel} from "../../SubscriptionPage/SubscriptionPage";

import SwipeCore from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
import {EffectCards} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/effect-cards';


const contentArr = Array.from({length: 3})

interface PricingCardComponentProps {
    data: FakeDataInt[];
    tab: string;
    setTab: Dispatch<SetStateAction<string>>
    currentPricingPeriod: string
}

const PricingCardComponent: React.FC<PricingCardComponentProps> = ({data, tab, setTab, currentPricingPeriod}) => {

    const swiperRef = useRef<SwipeCore>(null)

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            const index = data.findIndex(item => item.label === tab);
            if (index !== -1) {
                const newTab = data[index]
                swiperRef.current.swiper.slideTo(index);
                setTab(newTab.label)


            }
        }
    }, [data, tab]);

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.on('slideChange', () => {
                const activeIndex = swiperRef.current?.swiper?.activeIndex;
                if (activeIndex !== undefined && activeIndex >= 0 && activeIndex < pricingLabel.length) {
                    const newTab = pricingLabel[activeIndex];
                    setTab(newTab);
                }
            });
        }
    }, [setTab]);

    return <div className={'relative flex justify-content-center'}>
        <Swiper ref={swiperRef}

            style={{
                height: 'auto', width: ''
            }} effect={'cards'} grabCursor={'true'} modules={[EffectCards]}>
            {
                data.map(({label, price}, index) => {
                    const discount = 20 / 100
                    const yearlyPrice = price * 12
                    return (
                        <SwiperSlide key={index}>
                            <Card
                                className={`relative bg-white overflow-hidden ${label === 'Scale Up' ? `after:tracking-wide after:content-['Most_Popular'] after:font-semibold after:text-2xl' +
                    ' after:py-2.5 overflow-hidden after:bg-blue-100 after:border after:w-full after:absolute after:top-0 after:text-center` : ''}
                    ${label === tab ? 'z-10' : ''}
                
                    `}>
                                <CardHeader className={'mt-10 space-y-2'}>
                                    <CardTitle className={'text-3xl'}>{label}</CardTitle>
                                    <CardDescription
                                        className={'text-lg font-medium'}>
                                        <span>${currentPricingPeriod === 'per' +
                                        ' month' ? price : (yearlyPrice - (yearlyPrice * discount)).toFixed(2)}</span>
                                        <span
                                            className={`text-sm ml-1`}>{currentPricingPeriod}/team</span></CardDescription>
                                </CardHeader>
                                <CardContent className={'space-y-3'}>
                                    {contentArr.map((_value, index) => {
                                        return (
                                            <div className='flex gap-x-3 items-center' key={index}>
                                                <svg width="30" height="30" viewBox="0 0 15 15" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M7.49991 0.877045C3.84222 0.877045 0.877075 3.84219 0.877075 7.49988C0.877075 11.1575 3.84222 14.1227 7.49991 14.1227C11.1576 14.1227 14.1227 11.1575 14.1227 7.49988C14.1227 3.84219 11.1576 0.877045 7.49991 0.877045ZM1.82708 7.49988C1.82708 4.36686 4.36689 1.82704 7.49991 1.82704C10.6329 1.82704 13.1727 4.36686 13.1727 7.49988C13.1727 10.6329 10.6329 13.1727 7.49991 13.1727C4.36689 13.1727 1.82708 10.6329 1.82708 7.49988ZM10.1589 5.53774C10.3178 5.31191 10.2636 5.00001 10.0378 4.84109C9.81194 4.68217 9.50004 4.73642 9.34112 4.96225L6.51977 8.97154L5.35681 7.78706C5.16334 7.59002 4.84677 7.58711 4.64973 7.78058C4.45268 7.97404 4.44978 8.29061 4.64325 8.48765L6.22658 10.1003C6.33054 10.2062 6.47617 10.2604 6.62407 10.2483C6.77197 10.2363 6.90686 10.1591 6.99226 10.0377L10.1589 5.53774Z"
                                                        fill="green" fillRule="evenodd" clipRule="evenodd"></path>
                                                </svg>
                                                <p>USP</p>
                                            </div>
                                        )
                                    })}

                                </CardContent>
                                <CardFooter className={'flex justify-center'}>
                                    <Button
                                        className={'border rounded-lg px-10 py-1.5 bg-white hover:bg-gray-300/20'}>Choose</Button>
                                </CardFooter>
                            </Card>
                        </SwiperSlide>
                    )
                })
            }

        </Swiper>

    </div>
}

export default PricingCardComponent