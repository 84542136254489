/* eslint-disable no-unused-vars */

import { Form as FormikForm, Formik, FormikHelpers, FormikTouched, FormikValues } from 'formik';
import { FormikProps } from 'formik/dist/types';
import React, { Dispatch, JSX, Ref, SetStateAction, useEffect, useState } from 'react';
import { SaveButton } from '../../components/Button';

import FormControl from './FormControl';
import useForm from './useForm';
import { FormikRef } from './useFormikRef';
import { Schema } from './useSchema';


export type FormikConfig<Payload extends object> = {
    id?: string|number,
    initialValues?: FormikValues,
    onSubmit: (values: FormikValues, formikHelpers: FormikHelpers<FormikValues>) => void|Promise<any>,
    validationSchema: Schema<Payload>;
    validateOnChange: boolean,
    enableReinitialize: boolean,
    initialTouched?: FormikTouched<FormikValues>,
    initialized?: boolean,
    isSaved: boolean,
    setIsSaved: Dispatch<SetStateAction<boolean>>
}

// @todo form modal or portal.
export type ExtendFormProps<Dto> = {
    parentId?: number,
    id?: number,
    onSuccess?: (id: number|undefined) => void
}

export type FormProps<Dto extends object> = {
    formikConfig: FormikConfig<Dto>;
    formFields: JSX.Element[];
    formRef?: FormikRef<Dto>; // Update the type here
    withSaveButton?: boolean
    parentId?: number,
};

const Form = <Dto extends object>({ formikConfig, formFields, formRef, withSaveButton = false }: FormProps<Dto>): JSX.Element => {

    const [ currentConfig, setCurrentConfig ] = useState<FormikConfig<Dto>>(formikConfig);
    useEffect(() => {
        setCurrentConfig(formikConfig);
    }, [ formikConfig ]);

    const [ currentValues, setCurrentValues ] = useState<FormikValues>(formikConfig.initialValues ?? {});
    useEffect(() => {
        setCurrentValues(formikConfig.initialValues ?? {});
    }, [ formikConfig.initialValues ]);


    const [ currentFormFields, setCurrentFormFields ] = useState<JSX.Element[]>(formFields);
    useEffect(() => {
        setCurrentFormFields(formFields);
    }, [ formFields ]);

    return (
        <>
            <Formik { ...currentConfig } enableReinitialize={ true } initialValues={ currentValues } innerRef={ formRef as Ref<FormikProps<FormikValues>> }>
                <FormikForm>
                    { currentFormFields }
                    { withSaveButton && <SaveButton/> }
                </FormikForm>
            </Formik>
        </>
    );
};

export default Form;

export {
    FormControl,
    useForm
};
