import React, { FC, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import BasePage from '../../layout/BasePage';
import Agreement from '../../lib/samfe/components/Text/Agreement';
import H2 from '../../lib/samfe/components/Text/H2';
import H3 from '../../lib/samfe/components/Text/H3';
import List from '../../lib/samfe/components/Text/List';
import P from '../../lib/samfe/components/Text/P';
import Span from '../../lib/samfe/components/Text/Span';


const Conditions: FC = (): JSX.Element => {

    const navigate = useNavigate();

    return <BasePage
        title={ 'ToS' }
        navbarTop={ { hideNavbar: true, showBackButton: true } }
        navbarBottom={ { hideNavbar: true } }
    >

        <Agreement
            title={ 'Terms & Conditions' }
            description={ '2023-10-07' }
            onAccept={ () => {
                navigate('/');
            } }
            onDecline={ () => {
                // @todo what to do on decline?
                navigate('/');
            } }
        >
            <H2>1. Introduction</H2>
            <P>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut a iaculis orci. Maecenas tempus sem tortor. Etiam ac maximus ipsum, et venenatis augue. Quisque accumsan, felis vitae tristique hendrerit, metus purus aliquam turpis, ut laoreet mi sapien vitae diam. Duis quis ipsum nec sapien porttitor pulvinar vitae id risus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque fermentum nisl libero, a fermentum nunc fermentum ut. Nulla iaculis ipsum
                ut tincidunt ullamcorper.</P>
            <List state={ 'bullet' } items={ [
                <>Item with sub
                    <List state={ 'numerical' } items={ [
                        <>Item with sub
                            <List state={ 'alphabetical' } items={ [ 'item', 'item', 'item' ] }/>
                        </>,
                        'item',
                        'item'
                    ] }
                    />
                </>,
                'item',
                'item'
            ] }/>
            <List state={ 'numerical' } items={ [ 'item', 'item', 'item' ] }/>
            <List state={ 'alphabetical' } items={ [ 'item', 'item', 'item' ] }/>

            <H3>Sub heading</H3>
            <P>
                <Span state={ [ 'strong' ] }>Lorem</Span>&nbsp;
                <Span state={ [ 'italic' ] }>ipsum</Span>&nbsp;
                <Span state={ [ 'underline' ] }>dolor</Span>&nbsp;
                sit amet, consectetur adipiscing elit. Ut a iaculis orci. Maecenas tempus sem tortor. Etiam ac maximus ipsum, et venenatis augue. Quisque accumsan, felis vitae tristique hendrerit, metus purus aliquam turpis, ut laoreet mi sapien vitae diam. Duis quis ipsum nec sapien porttitor pulvinar vitae id risus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque fermentum nisl libero, a fermentum nunc fermentum ut. Nulla iaculis ipsum ut tincidunt
                ullamcorper.</P>

        </Agreement>

    </BasePage>;
};
export default Conditions;