import React, { FC } from 'react';
import { classNames } from '../../modules/Parse/String';
import { SpanType } from './TextTypes';


const Span: FC<SpanType> = ({ state, className, children, ...props }) => <span { ...props }
    className={ classNames('text-neutral text-sm',
        state.includes('strong') && 'font-bold',
        state.includes('italic') && 'italic',
        state.includes('underline') && 'underline',
        className
    ) }
>
    { children }
</span>;

export default Span;