import { Listbox, Transition } from '@headlessui/react';
import { ArrowDropDown, Check } from '@mui/icons-material';
import React, { Fragment, JSX, useEffect, useState } from 'react';
import { classNames } from '../../../modules/Parse/String';
import FieldControl from './FieldControl';
import { BaseFieldProps } from './FieldTypes';


export type SelectConfig<Model extends object> = {
    initialModel?: Model,
    valueCol: keyof Model,
    displayCol: keyof Model
    models: Model[]
}

type Props<Model extends object> = BaseFieldProps&{
    selectConfig: SelectConfig<Model>,
    // eslint-disable-next-line no-unused-vars
    onChange?: (model: Model) => void
}

const Select = <Model extends object>({
    fieldControl,
    name,
    withFormik,
    selectConfig
}: Props<Model>): JSX.Element => {

    const { initialModel, models, valueCol, displayCol } = selectConfig;

    const getSelected = (_models?: Model[]) => (initialModel && (_models ?? models).find(model => model[valueCol] == initialModel[valueCol])) ?? (models)[0];
    const [ selected, setSelected ] = useState(getSelected());

    useEffect(() => {
        // Check if a value is already selected, if so no further action needed
        if (selected && models.find(model => model[valueCol] == selected[valueCol]) == undefined) {
            return;
        }
        // Set a selected option from changed option list.
        setSelected(getSelected(models));
    }, [ models ]);


    return <FieldControl
        name={ name }
        value={ selected[valueCol] as string|number ?? '' }
        withFormik={ withFormik }
        formControl={ {
            ...fieldControl,
            label: {
                name: fieldControl?.label
            },
            description: {
                text: fieldControl?.description
            }
        }
        }
    >
        <Listbox value={ selected } onChange={ setSelected }>
            { ({ open }) => (
                <>
                    <div className="relative">

                        <Listbox.Button className="relative w-full cursor-default rounded bg-primary-100 py-1.5 pl-3 pr-10 text-left text-primary">
                            <span className="block truncate">{ `${ selected[displayCol] }` }</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ArrowDropDown className="h-5 w-5 text-primary"/>
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={ open }
                            as={ Fragment }
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                { models.map((model, i) => (
                                    <Listbox.Option
                                        key={ i }
                                        className={ ({ active }) =>
                                            classNames(
                                                active ?'bg-primary text-white' :'text-neutral',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={ model }
                                    >
                                        { ({ selected, active }) => (
                                            <>
                                                <span className={ classNames(selected ?'font-semibold' :'font-normal', 'block truncate') }>
                                                    { `${ model[displayCol] }` }
                                                </span>

                                                { selected ?(
                                                    <span
                                                        className={ classNames(
                                                            active ?'text-white' :'text-primary',
                                                            'absolute inset-y-0 right-0 flex items-center pr-2'
                                                        ) }
                                                    >
                                                        <Check className="!text-[1.25rem]"/>
                                                    </span>
                                                ) :null }
                                            </>
                                        ) }
                                    </Listbox.Option>
                                )) }
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            ) }
        </Listbox>
    </FieldControl>;
};
export default Select;