/**
 * Format string to small letters with Capital first.
 *
 * @param {string} str
 * @returns {string}
 */
export const ucFirst = (str?: string|number): string => (str && typeof str == 'string' && str[0].toUpperCase() + str.slice(1)) || str?.toString() || '';


/**
 * Add comma seperated classnames to components.
 *
 * @param {(string|false|undefined)[]} classNames
 * @returns {string}
 */
export const classNames = (...classNames: (string|undefined|false)[]): string => classNames.filter(Boolean).join(' ');


/**
 * Parse string to readable url safe string.
 *
 * @param {string} str
 * @returns {string} Url safe parsed string.
 */
export const urlSafe = (str: string): string => encodeURIComponent(str.replace(/\s+/g, '-').toLowerCase());

