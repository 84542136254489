import React from 'react'
import {FormControl, FormField, FormItem, FormLabel} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {UseFormReturn} from "react-hook-form";
import {z} from "zod";
import {formSchema} from "../../CheckOutPage/CheckOutPage";
import {FormSchemaKeys} from "./CheckOutFormComponent";

interface InputSameLineComponentProps {
    form: UseFormReturn<z.infer<typeof formSchema>>;
    formName1: FormSchemaKeys;
    formName2: FormSchemaKeys;
    containerClass: string;
    firstInputClass: string;
    secondInputClass: string;
    label1: string;
    label2: string;
}

const InputSameLineComponent: React.FC<InputSameLineComponentProps> = ({
    form,
    formName1,
    formName2,
    containerClass,
    firstInputClass,
    secondInputClass,
    label1,
    label2,
}) => {
    return (
        <FormItem className={containerClass}>
            <FormField control={form.control} name={formName1} render={({field}) => {
                return (
                    <FormItem className={firstInputClass}>
                        <FormLabel>{label1}</FormLabel>
                        <FormControl>
                            <Input {...field}/>
                        </FormControl>
                    </FormItem>
                )

            }}/>
            <FormField control={form.control} name={formName2} render={({field}) => {
                return (
                    <FormItem className={secondInputClass}>
                        <FormLabel>{label2}</FormLabel>
                        <FormControl>
                            <Input {...field}/>
                        </FormControl>
                    </FormItem>
                )

            }}/>
        </FormItem>
    )
}

export default InputSameLineComponent