import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const useAuthGuard = () => {

    const { isAuthenticated, isLoading } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoading) {return;}
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [ isAuthenticated, isLoading ]);

};
export default useAuthGuard;