import React from 'react'
import {FormControl, FormField, FormItem, FormLabel} from "@/components/ui/form";
import {Checkbox} from "@/components/ui/checkbox";
import {UseFormReturn} from "react-hook-form";
import {z} from "zod";
import {formSchema} from "../../CheckOutPage/CheckOutPage";
import {FormSchemaKeys} from "./CheckOutFormComponent";

interface InputCheckboxComponentProps {
    form: UseFormReturn<z.infer<typeof formSchema>>;
    formName: FormSchemaKeys;
    message: string;
}

const InputCheckboxComponent: React.FC<InputCheckboxComponentProps> = ({form, formName, message}) => {
    return (
        <FormField control={form.control} name={formName} render={({field}) => {
            return (
                <FormItem className={'flex items-center space-y-0 gap-x-2'}>
                    <FormControl className="flex items-center space-x-2">
                        <Checkbox checked={field.value} onCheckedChange={field.onChange}
                            className={'text-white'}/>

                    </FormControl>
                    <div>
                        <FormLabel
                            className={"text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"}>
                            {message}
                        </FormLabel>
                    </div>
                </FormItem>
            )
        }}

        />
    )
}

export default InputCheckboxComponent