import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';


type UseIdToken = {
    idToken?: string,
    // eslint-disable-next-line no-unused-vars
    setIdToken: (idToken?: string) => void,
    destroyIdToken: () => void,
    hasIdToken: () => boolean
}

/**
 * Get, Set, Remove and check token.
 */
const useIdToken = (): UseIdToken => {

    const cookieName = 'tjecko_auth_token';
    const [ cookies, setCookie, removeCookie ] = useCookies([ cookieName ]);

    const idToken: string|undefined = cookies[cookieName];
    const setIdToken = (idToken?: string): void => setCookie(cookieName, idToken);
    const destroyIdToken = (): void => removeCookie(cookieName);
    const hasIdToken = (): boolean => idToken !== undefined;

    const [ prevIdToken, setPrevIdToken ] = useState(idToken);
    useEffect(() => {
        if (prevIdToken == idToken) {
            return;
        }
        setPrevIdToken(idToken);
    }, [ idToken ]);

    return {
        idToken,
        setIdToken,
        destroyIdToken,
        hasIdToken
    };
};

export default useIdToken;


type IdTokenProviderProps = {
    children: ReactNode;
}

/**
 * Set token context.
 */
const IdTokenCtx = createContext<string|undefined>(undefined);


/**
 * Provider for application wide access.
 * @param children
 * @constructor
 */
export const IdTokenProvider: FC<IdTokenProviderProps> = ({ children }) => {
    const { idToken } = useIdToken();

    return (
        <IdTokenCtx.Provider value={ idToken }>
            { children }
        </IdTokenCtx.Provider>
    );
};


/**
 * Get up-to-date value application wide.
 */
export const useIdTokenCtx = () => useContext(IdTokenCtx);



