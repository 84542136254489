import React from 'react'
import InputComponent from "./InputComponent";
import {Button} from "@/components/ui/button";
import {Profile} from "../RegistrationPage/CreateProfileScreen";
import {Company} from "../RegistrationPage/CreateCompanyScreen";


interface FormComponentProps {
    keys: string[]
    // eslint-disable-next-line no-unused-vars
    handleSubmit: (e: React.FormEvent<HTMLFormElement | HTMLButtonElement>) => void;
    // eslint-disable-next-line no-unused-vars
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label: string[];
    value: Profile | Company;
}


const FormComponent: React.FC<FormComponentProps> = ({
    keys,
    handleSubmit,
    handleChange,
    label,
    value,
}) => {
    const valueArr = Object.values(value)

    return <form onSubmit={handleSubmit} className='flex flex-col flex-grow'>
        <div className='flex-grow space-y-2.5'>
            {keys.map((key, index) => {
                return <InputComponent inputKey={key} handleChange={handleChange}
                    label={label[index]}
                    value={valueArr[index]}
                    key={index}
                />
            })}

        </div>

        <Button
            type='submit'
            onSubmit={handleSubmit}
            className='bg-white border hover:text-white mt-10'>
            Save
        </Button>
    </form>
}

export default FormComponent