import React, { FC } from 'react';
import { JSX } from 'react/index';
import { classNames } from '../../modules/Parse/String';
import { AlphaNumeric } from '../../Samfe';


type Props = {
    abbreviation: `${ AlphaNumeric }`|`${ AlphaNumeric }${ AlphaNumeric }`
    className?: string
}

const DetailTextImage: FC<Props> = ({ abbreviation, className }): JSX.Element => {
    return <div
        className={ classNames('h-10 w-10 rounded-full bg-primary text-white text-md relative inline-block font-medium', className) }
    >
        <span className={ 'absolute w-full text-center translate-y-1/3' }>{ abbreviation.toUpperCase() }</span>
    </div>;
};

export default DetailTextImage;