import React, { FC, JSX } from 'react';


type Props = {
    message?: string
}
const ErrorMessage: FC<Props> = ({ message }): JSX.Element => {
    return <>
        { message && <span className={ 'text-danger text-sm mt-1 block' }>{ message }</span> }
    </>;
};
export default ErrorMessage;