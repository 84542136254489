import React, { DetailedHTMLProps, FC, HTMLAttributes, useEffect, useId, useRef, useState } from 'react';
import { classNames } from '../../modules/Parse/String';
import AcceptButton from '../Button/AcceptButton';
import DeclineButton from '../Button/DeclineButton';
import Description from './Description';


type Props = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>&{
    title: string,
    description?: string,
    onAccept: () => void|Promise<void>
    onDecline: () => void|Promise<void>
}

const Agreement: FC<Props> = ({
    title,
    description,
    onAccept,
    onDecline,
    children,
    className,
    id,
    ...props
}) => {


    const checkboxId = useId();
    const articleId = id ?? useId();
    const articleRef = useRef<HTMLElement|null>(null);

    const [ isChecked, setIscChecked ] = useState(false);
    const [ highlightCheckbox, setHighlightCheckbox ] = useState(false);

    useEffect(() => {
        articleRef.current = document.getElementById(articleId);
    }, []);


    const handleCheckboxClick = () => {
        const newChecked = !isChecked;
        setIscChecked(newChecked);
        if (newChecked) {
            setHighlightCheckbox(false);
        }
    };


    /**
     *
     */
    const handleAcceptClick = async() => {
        if (isChecked) {
            await onAccept();
            return;
        }
        articleRef.current!.scrollTop = articleRef.current!.scrollHeight;
        setHighlightCheckbox(true);
    };


    /**
     *
     */
    const handleDeclineClick = async() => await onDecline();


    return <>
        <div className="mb-4 pb-4 border-b">
            <h1 className={ 'font-bold text-neutral text-lg' }>{ title }</h1>
            { description && <Description>{ description }</Description> }
        </div>

        <article { ...props } id={ articleId } className={ classNames('scroll-smooth h-[calc(100vh-10rem)] absolute w-full left-0 px-4 overflow-y-scroll pb-2', className) }>
            { children }

            <br/>

            <input id={ checkboxId } className={ classNames(highlightCheckbox && 'border border-red-600 ', 'mr-2') } type={ 'checkbox' } onClick={ handleCheckboxClick }/>
            <label htmlFor={ checkboxId } className={ classNames('text-sm', highlightCheckbox && 'text-danger') }>Ik heb alles gelezen en ik ga akkoord</label>
        </article>

        <div className="grid grid-cols-2 gap-4 mt-5 absolute top-[calc(100vh-5.25rem)] w-full left-0 px-4 border-t pt-4">
            <DeclineButton state={ 'secondary' } onClick={ handleDeclineClick }/>
            <AcceptButton disabled={ !isChecked } onClick={ handleAcceptClick }/>
        </div>
    </>;
};

export default Agreement;