import React, { FC, JSX } from 'react';
import BasePage from '../../layout/BasePage';


const Typography: FC = (): JSX.Element => {

    return <BasePage title={ 'Typography' } navbarTop={ {
        showBackButton: true
    } }>
        TODO
    </BasePage>;
};
export default Typography;