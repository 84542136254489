/**
 *
 * @param dt
 * @param notation
 * @param locale
 */
export const datetimeToLocale = (dt?: string, notation: 'long'|'short' = 'short', locale: string|undefined = 'nl-NL'): string => new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: notation,
    day: 'numeric'
}).format(dt ?new Date(dt) :new Date());


/**
 * Compare two dates.
 *
 * @example ASC:  A > B -> -1 | 1
 * @example DESC: A < B -> -1 | 1
 * @example EQUAL: A == B -> 0
 *
 * @param {string|undefined} a
 * @param {string|undefined} b
 * @param {'ASC'|'DESC'} sort
 * @returns {number}
 */
export const SortByDate = (a?: string, b?: string, sort: 'ASC'|'DESC' = 'ASC'): number => {
    const aMs = new Date(a ?? '').getTime();
    const bMs = new Date(b ?? '').getTime();
    if (aMs == bMs) {return 0;}
    return (sort == 'ASC' ?(aMs>bMs) :(aMs<bMs)) ?-1 :1;
};


/**
 * Parse JS date object to SQL date in format "yyyy-mm-dd".
 *
 * @description provides current date if no parameter is given.
 * @param {Date|undefined} date
 * @returns {string} SQL date
 */
export const jsDateToSqlDate = (date?: Date): string => ((date ?? new Date())
    .toISOString()
    .slice(0, -5)
    .replace('T', ' ')
);


/**
 * Parse sql datetime to date.
 *
 * @description provides current date if no parameter is given.
 * @param {string|undefined} date in format "yyyy-mm-dd H:i:s"
 * @returns {string} SQL date "yyyy-mm-dd"
 */
export const sqlDateTimeToDate = (date?: string): string => ((date ?? jsDateToSqlDate())
    .slice(0, 10)
);
