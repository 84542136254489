import useGraphQL from '../../../lib/samfe/modules/graphql/useGraphQL';
import {GetIdentitiesDocument, GetIdentitiesQuery, GetIdentitiesQueryVariables, Identity} from "../../../gql/schema";


const useIdentity = () => {
    const { querySingle } = useGraphQL<Identity>();
    return {
        getSingle: querySingle<GetIdentitiesQueryVariables, GetIdentitiesQuery>(GetIdentitiesDocument, 'identities')
    };
};

export default useIdentity;