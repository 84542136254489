import { DoNotDisturb } from '@mui/icons-material';
import React, { FC, JSX } from 'react';
import Button, { ButtonProps } from './Button';


/**
 *
 * @param props
 * @constructor
 */
const DeclineButton: FC<ButtonProps> = (props): JSX.Element => (
    <Button { ...props } textColor={ 'white' } bgColor={ 'primary' } Icon={ DoNotDisturb }>
        Decline
    </Button>
);

export default DeclineButton;