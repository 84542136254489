/* eslint-disable */
import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AddressModel = {
  __typename?: 'AddressModel';
  address: Scalars['String']['output'];
  addressType: Scalars['String']['output'];
  city: Scalars['String']['output'];
  company: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  extra: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  postalCode: Scalars['String']['output'];
  province: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BaseModel = {
  __typename?: 'BaseModel';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type BenefitModel = {
  __typename?: 'BenefitModel';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CocInfoModel = {
  __typename?: 'CocInfoModel';
  addressId?: Maybe<Scalars['ID']['output']>;
  coc?: Maybe<Scalars['String']['output']>;
  company?: Maybe<CompanyModel>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  vat?: Maybe<Scalars['String']['output']>;
};

export type CompanyModel = {
  __typename?: 'CompanyModel';
  addresses?: Maybe<Array<Maybe<AddressModel>>>;
  cocInfo?: Maybe<CocInfoModel>;
  contacts?: Maybe<Array<Maybe<ContactModel>>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  theme?: Maybe<MerchantThemeModel>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ConditionModel = {
  __typename?: 'ConditionModel';
  condition?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ContactModel = {
  __typename?: 'ContactModel';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  middleName: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  position: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CreateAddressInput = {
  address: Scalars['String']['input'];
  addressType: Scalars['String']['input'];
  city: Scalars['String']['input'];
  company: Scalars['String']['input'];
  extra: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  province: Scalars['String']['input'];
};

export type CreateBenefitInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateCocInfoInput = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  coc?: InputMaybe<Scalars['String']['input']>;
  companyId: Scalars['ID']['input'];
  vat?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyInput = {
  name: Scalars['String']['input'];
};

export type CreateConditionInput = {
  condition?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateContactInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  middleName: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  position: Scalars['String']['input'];
};

export type CreateDiscountCodeInput = {
  availableAmount?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount: Scalars['Float']['input'];
  discountType: Scalars['String']['input'];
  title: Scalars['String']['input'];
  useBefore?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateExtraInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
  when: Scalars['String']['input'];
};

export type CreateMerchantThemeInput = {
  companyLogo?: InputMaybe<Scalars['String']['input']>;
  isFormal?: InputMaybe<Scalars['Boolean']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  secondaryColor?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSubscriptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateTierInput = {
  Extends?: InputMaybe<Scalars['ID']['input']>;
  Frequency: Scalars['String']['input'];
  Price: Scalars['Float']['input'];
  SubscriptionId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
};

export type CreateUserInput = {
  name: Scalars['String']['input'];
};

export type DiscountCodeModel = {
  __typename?: 'DiscountCodeModel';
  availableAmount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  discount: Scalars['Float']['output'];
  discountType: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  useBefore?: Maybe<Scalars['DateTime']['output']>;
};

export type ExtraModel = {
  __typename?: 'ExtraModel';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  when: Scalars['String']['output'];
};

export type Identity = {
  __typename?: 'Identity';
  domain: Scalars['String']['output'];
  user: User;
};

export type MerchantOnboardingProgress = {
  __typename?: 'MerchantOnboardingProgress';
  addressIsComplete: Scalars['Boolean']['output'];
  contactIsComplete: Scalars['Boolean']['output'];
  memberIsComplete: Scalars['Boolean']['output'];
  subscriptionIsComplete: Scalars['Boolean']['output'];
};

export type MerchantProfileProgress = {
  __typename?: 'MerchantProfileProgress';
  companyId: Scalars['ID']['output'];
  complete: Scalars['Boolean']['output'];
  onboardingProgress: MerchantOnboardingProgress;
};

export type MerchantThemeModel = {
  __typename?: 'MerchantThemeModel';
  companyLogo?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  isFormal?: Maybe<Scalars['Boolean']['output']>;
  primaryColor?: Maybe<Scalars['String']['output']>;
  secondaryColor?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type MollieAuthorization = {
  __typename?: 'MollieAuthorization';
  authorizeMollieId?: Maybe<Scalars['String']['output']>;
  isAuthorized?: Maybe<Scalars['Boolean']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTermsForUser?: Maybe<Scalars['Boolean']['output']>;
  addAddressToCompany?: Maybe<Scalars['Boolean']['output']>;
  addAddressToUser?: Maybe<Scalars['Boolean']['output']>;
  addCocToCompany?: Maybe<Scalars['Boolean']['output']>;
  addCompanyToUser?: Maybe<Scalars['Boolean']['output']>;
  addContactToCompany?: Maybe<Scalars['Boolean']['output']>;
  addImageToCoc?: Maybe<Scalars['Boolean']['output']>;
  addThemeToCompany?: Maybe<Scalars['Boolean']['output']>;
  addTierToUser: Scalars['String']['output'];
  completeTierPurchase: Scalars['ID']['output'];
  createAddress: AddressModel;
  createBenefit: BenefitModel;
  createCocInfo: CocInfoModel;
  createCompany: CompanyModel;
  createCondition: ConditionModel;
  createContact: ContactModel;
  createDiscountCode: DiscountCodeModel;
  createExtra: ExtraModel;
  createMerchantTheme: MerchantThemeModel;
  createSubscription: SubscriptionModel;
  createTier: TierModel;
  createUser: UserModel;
  deleteAddress: Scalars['Boolean']['output'];
  deleteBenefit: Scalars['Boolean']['output'];
  deleteCocInfo: Scalars['Boolean']['output'];
  deleteCompany: Scalars['Boolean']['output'];
  deleteCondition: Scalars['Boolean']['output'];
  deleteContact: Scalars['Boolean']['output'];
  deleteDiscountCode: Scalars['Boolean']['output'];
  deleteExtra: Scalars['Boolean']['output'];
  deleteMerchantTheme: Scalars['Boolean']['output'];
  deleteSubscription: Scalars['Boolean']['output'];
  deleteTier: Scalars['Boolean']['output'];
  deleteUser: Scalars['Boolean']['output'];
  removeAddressFromCompany?: Maybe<Scalars['Boolean']['output']>;
  removeAddressFromUser?: Maybe<Scalars['Boolean']['output']>;
  removeCocFromCompany?: Maybe<Scalars['Boolean']['output']>;
  removeCompanyFromUser?: Maybe<Scalars['Boolean']['output']>;
  removeContactFromCompany?: Maybe<Scalars['Boolean']['output']>;
  removeImageFromCoc?: Maybe<Scalars['Boolean']['output']>;
  removeThemeFromCompany?: Maybe<Scalars['Boolean']['output']>;
  unacceptTermsForUser?: Maybe<Scalars['Boolean']['output']>;
  updateAddress: AddressModel;
  updateBenefit: BenefitModel;
  updateCocInfo: CocInfoModel;
  updateCompany: CompanyModel;
  updateCondition: ConditionModel;
  updateContact: ContactModel;
  updateDiscountCode: DiscountCodeModel;
  updateExtra: ExtraModel;
  updateMerchantTheme: MerchantThemeModel;
  updateSubscription: SubscriptionModel;
  updateTier: TierModel;
  updateUser: UserModel;
};


export type MutationAcceptTermsForUserArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddAddressToCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddAddressToUserArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddCocToCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddCompanyToUserArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddContactToCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddImageToCocArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddThemeToCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationAddTierToUserArgs = {
  discount?: InputMaybe<Scalars['ID']['input']>;
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationCompleteTierPurchaseArgs = {
  mollieId: Scalars['ID']['input'];
};


export type MutationCreateAddressArgs = {
  input: CreateAddressInput;
};


export type MutationCreateBenefitArgs = {
  input: CreateBenefitInput;
};


export type MutationCreateCocInfoArgs = {
  input: CreateCocInfoInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateConditionArgs = {
  input: CreateConditionInput;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationCreateDiscountCodeArgs = {
  input: CreateDiscountCodeInput;
};


export type MutationCreateExtraArgs = {
  input: CreateExtraInput;
};


export type MutationCreateMerchantThemeArgs = {
  input: CreateMerchantThemeInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationCreateTierArgs = {
  input: CreateTierInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteAddressArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteBenefitArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCocInfoArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteConditionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteContactArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDiscountCodeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteExtraArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteMerchantThemeArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTierArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveAddressFromCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveAddressFromUserArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveCocFromCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveCompanyFromUserArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveContactFromCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveImageFromCocArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationRemoveThemeFromCompanyArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationUnacceptTermsForUserArgs = {
  record: Scalars['ID']['input'];
  relation: Scalars['ID']['input'];
};


export type MutationUpdateAddressArgs = {
  id: Scalars['ID']['input'];
  input: UpdateAddressInput;
};


export type MutationUpdateBenefitArgs = {
  id: Scalars['ID']['input'];
  input: UpdateBenefitInput;
};


export type MutationUpdateCocInfoArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCocInfoInput;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['ID']['input'];
  input: UpdateCompanyInput;
};


export type MutationUpdateConditionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateConditionInput;
};


export type MutationUpdateContactArgs = {
  id: Scalars['ID']['input'];
  input: UpdateContactInput;
};


export type MutationUpdateDiscountCodeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateDiscountCodeInput;
};


export type MutationUpdateExtraArgs = {
  id: Scalars['ID']['input'];
  input: UpdateExtraInput;
};


export type MutationUpdateMerchantThemeArgs = {
  id: Scalars['ID']['input'];
  input: UpdateMerchantThemeInput;
};


export type MutationUpdateSubscriptionArgs = {
  id: Scalars['ID']['input'];
  input: UpdateSubscriptionInput;
};


export type MutationUpdateTierArgs = {
  id: Scalars['ID']['input'];
  input: UpdateTierInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID']['input'];
  input: UpdateUserInput;
};

export type Query = {
  __typename?: 'Query';
  address?: Maybe<AddressModel>;
  addresses?: Maybe<Array<Maybe<AddressModel>>>;
  benefit?: Maybe<BenefitModel>;
  benefits?: Maybe<Array<Maybe<BenefitModel>>>;
  companies?: Maybe<Array<Maybe<CompanyModel>>>;
  company?: Maybe<CompanyModel>;
  condition?: Maybe<ConditionModel>;
  conditions?: Maybe<Array<Maybe<ConditionModel>>>;
  contact?: Maybe<ContactModel>;
  contacts?: Maybe<Array<Maybe<ContactModel>>>;
  discountCode?: Maybe<DiscountCodeModel>;
  discountCodes?: Maybe<Array<Maybe<DiscountCodeModel>>>;
  extra?: Maybe<ExtraModel>;
  extras?: Maybe<Array<Maybe<ExtraModel>>>;
  identities: Identity;
  merchantOnboarding?: Maybe<MerchantOnboardingProgress>;
  merchantProfile?: Maybe<MerchantProfileProgress>;
  merchantTheme?: Maybe<MerchantThemeModel>;
  merchantThemes?: Maybe<Array<Maybe<MerchantThemeModel>>>;
  mollieToken?: Maybe<MollieAuthorization>;
  subscription?: Maybe<SubscriptionModel>;
  subscriptions?: Maybe<Array<Maybe<SubscriptionModel>>>;
  tier?: Maybe<TierModel>;
  tiers?: Maybe<Array<Maybe<TierModel>>>;
  user?: Maybe<UserModel>;
  userOnboarding?: Maybe<UserOnboardingProgress>;
  userProfile?: Maybe<UserProfileProgress>;
  users?: Maybe<Array<Maybe<UserModel>>>;
};


export type QueryAddressArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBenefitArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryConditionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContactArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDiscountCodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryExtraArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMerchantOnboardingArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryMerchantProfileArgs = {
  companyId: Scalars['ID']['input'];
};


export type QueryMerchantThemeArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTierArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserOnboardingArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryUserProfileArgs = {
  userId: Scalars['ID']['input'];
};

export type SubscriptionModel = {
  __typename?: 'SubscriptionModel';
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type TierModel = {
  __typename?: 'TierModel';
  Extends?: Maybe<Scalars['ID']['output']>;
  Frequency: Scalars['String']['output'];
  Price: Scalars['Float']['output'];
  SubscriptionId: Scalars['ID']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UpdateAddressInput = {
  address?: InputMaybe<Scalars['String']['input']>;
  addressType?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  extra?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBenefitInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCocInfoInput = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  coc?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['ID']['input']>;
  vat?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyInput = {
  name: Scalars['String']['input'];
};

export type UpdateConditionInput = {
  condition?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  middleName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateDiscountCodeInput = {
  availableAmount?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  discount?: InputMaybe<Scalars['Float']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  useBefore?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateExtraInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  when?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateMerchantThemeInput = {
  companyLogo?: InputMaybe<Scalars['String']['input']>;
  isFormal?: InputMaybe<Scalars['Boolean']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  secondaryColor?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateSubscriptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTierInput = {
  Extends?: InputMaybe<Scalars['ID']['input']>;
  Frequency?: InputMaybe<Scalars['String']['input']>;
  Price?: InputMaybe<Scalars['Float']['input']>;
  SubscriptionId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  name: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  latestTerms: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UserModel = {
  __typename?: 'UserModel';
  address?: Maybe<Array<Maybe<AddressModel>>>;
  companies?: Maybe<Array<Maybe<CompanyModel>>>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  eol?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  secret: Scalars['String']['output'];
  subscriptionTiers?: Maybe<Array<Maybe<TierModel>>>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserOnboardingProgress = {
  __typename?: 'UserOnboardingProgress';
  addressIsComplete: Scalars['Boolean']['output'];
  companyIsComplete: Scalars['Boolean']['output'];
};

export type UserProfileProgress = {
  __typename?: 'UserProfileProgress';
  complete: Scalars['Boolean']['output'];
  onboardingProgress: UserOnboardingProgress;
  userId: Scalars['ID']['output'];
};

export type BaseCompanyFragment = { __typename?: 'CompanyModel', id: string, name: string, createdAt: any, updatedAt: any };

export type GetCompanyQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCompanyQuery = { __typename?: 'Query', company?: { __typename?: 'CompanyModel', id: string, name: string, createdAt: any, updatedAt: any } | null };

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCompaniesQuery = { __typename?: 'Query', companies?: Array<{ __typename?: 'CompanyModel', id: string, name: string, createdAt: any, updatedAt: any } | null> | null };

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', createCompany: { __typename?: 'CompanyModel', id: string, name: string, createdAt: any, updatedAt: any } };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename?: 'CompanyModel', id: string, name: string, createdAt: any, updatedAt: any } };

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteCompanyMutation = { __typename?: 'Mutation', deleteCompany: boolean };

export type AddCocToCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AddCocToCompanyMutation = { __typename?: 'Mutation', addCocToCompany?: boolean | null };

export type AddAddressToCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AddAddressToCompanyMutation = { __typename?: 'Mutation', addAddressToCompany?: boolean | null };

export type AddThemeToCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AddThemeToCompanyMutation = { __typename?: 'Mutation', addThemeToCompany?: boolean | null };

export type AddContactToCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AddContactToCompanyMutation = { __typename?: 'Mutation', addContactToCompany?: boolean | null };

export type RemoveCocFromCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type RemoveCocFromCompanyMutation = { __typename?: 'Mutation', removeCocFromCompany?: boolean | null };

export type RemoveAddressFromCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type RemoveAddressFromCompanyMutation = { __typename?: 'Mutation', removeAddressFromCompany?: boolean | null };

export type RemoveThemeFromCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type RemoveThemeFromCompanyMutation = { __typename?: 'Mutation', removeThemeFromCompany?: boolean | null };

export type RemoveContactFromCompanyMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type RemoveContactFromCompanyMutation = { __typename?: 'Mutation', removeContactFromCompany?: boolean | null };

export type BaseMerchantThemeFragment = { __typename?: 'MerchantThemeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, companyLogo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, isFormal?: boolean | null };

export type GetMerchantThemeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetMerchantThemeQuery = { __typename?: 'Query', merchantTheme?: { __typename?: 'MerchantThemeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, companyLogo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, isFormal?: boolean | null } | null };

export type GetMerchantThemesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMerchantThemesQuery = { __typename?: 'Query', merchantThemes?: Array<{ __typename?: 'MerchantThemeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, companyLogo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, isFormal?: boolean | null } | null> | null };

export type CreateMerchantThemeMutationVariables = Exact<{
  input: CreateMerchantThemeInput;
}>;


export type CreateMerchantThemeMutation = { __typename?: 'Mutation', createMerchantTheme: { __typename?: 'MerchantThemeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, companyLogo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, isFormal?: boolean | null } };

export type UpdateMerchantThemeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateMerchantThemeInput;
}>;


export type UpdateMerchantThemeMutation = { __typename?: 'Mutation', updateMerchantTheme: { __typename?: 'MerchantThemeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, companyLogo?: string | null, primaryColor?: string | null, secondaryColor?: string | null, isFormal?: boolean | null } };

export type DeleteMerchantThemeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteMerchantThemeMutation = { __typename?: 'Mutation', deleteMerchantTheme: boolean };

export type BaseAddressFragment = { __typename?: 'AddressModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string };

export type GetAddressQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetAddressQuery = { __typename?: 'Query', address?: { __typename?: 'AddressModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string } | null };

export type GetAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAddressesQuery = { __typename?: 'Query', addresses?: Array<{ __typename?: 'AddressModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string } | null> | null };

export type CreateAddressMutationVariables = Exact<{
  input: CreateAddressInput;
}>;


export type CreateAddressMutation = { __typename?: 'Mutation', createAddress: { __typename?: 'AddressModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string } };

export type UpdateAddressMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateAddressInput;
}>;


export type UpdateAddressMutation = { __typename?: 'Mutation', updateAddress: { __typename?: 'AddressModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, addressType: string, company: string, address: string, extra: string, city: string, province: string, postalCode: string } };

export type DeleteAddressMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteAddressMutation = { __typename?: 'Mutation', deleteAddress: boolean };

export type BaseContactFragment = { __typename?: 'ContactModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, firstName: string, middleName: string, lastName: string, position: string, email: string, phone: string };

export type GetContactQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetContactQuery = { __typename?: 'Query', contact?: { __typename?: 'ContactModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, firstName: string, middleName: string, lastName: string, position: string, email: string, phone: string } | null };

export type GetContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactsQuery = { __typename?: 'Query', contacts?: Array<{ __typename?: 'ContactModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, firstName: string, middleName: string, lastName: string, position: string, email: string, phone: string } | null> | null };

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;


export type CreateContactMutation = { __typename?: 'Mutation', createContact: { __typename?: 'ContactModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, firstName: string, middleName: string, lastName: string, position: string, email: string, phone: string } };

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateContactInput;
}>;


export type UpdateContactMutation = { __typename?: 'Mutation', updateContact: { __typename?: 'ContactModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, firstName: string, middleName: string, lastName: string, position: string, email: string, phone: string } };

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteContactMutation = { __typename?: 'Mutation', deleteContact: boolean };

export type BaseBenefitFragment = { __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null };

export type GetBenefitQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetBenefitQuery = { __typename?: 'Query', benefit?: { __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null } | null };

export type GetBenefitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBenefitsQuery = { __typename?: 'Query', benefits?: Array<{ __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null } | null> | null };

export type CreateBenefitMutationVariables = Exact<{
  input: CreateBenefitInput;
}>;


export type CreateBenefitMutation = { __typename?: 'Mutation', createBenefit: { __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null } };

export type UpdateBenefitMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateBenefitInput;
}>;


export type UpdateBenefitMutation = { __typename?: 'Mutation', updateBenefit: { __typename?: 'BenefitModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null } };

export type DeleteBenefitMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteBenefitMutation = { __typename?: 'Mutation', deleteBenefit: boolean };

export type BaseConditionFragment = { __typename?: 'ConditionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, condition?: string | null };

export type GetConditionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetConditionQuery = { __typename?: 'Query', condition?: { __typename?: 'ConditionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, condition?: string | null } | null };

export type GetConditionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConditionsQuery = { __typename?: 'Query', conditions?: Array<{ __typename?: 'ConditionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, condition?: string | null } | null> | null };

export type CreateConditionMutationVariables = Exact<{
  input: CreateConditionInput;
}>;


export type CreateConditionMutation = { __typename?: 'Mutation', createCondition: { __typename?: 'ConditionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, condition?: string | null } };

export type UpdateConditionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateConditionInput;
}>;


export type UpdateConditionMutation = { __typename?: 'Mutation', updateCondition: { __typename?: 'ConditionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, condition?: string | null } };

export type DeleteConditionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteConditionMutation = { __typename?: 'Mutation', deleteCondition: boolean };

export type BaseDiscountCodeFragment = { __typename?: 'DiscountCodeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, useBefore?: any | null, title: string, description?: string | null, discount: number };

export type GetDiscountCodeQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDiscountCodeQuery = { __typename?: 'Query', discountCode?: { __typename?: 'DiscountCodeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, useBefore?: any | null, title: string, description?: string | null, discount: number } | null };

export type GetDiscountCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDiscountCodesQuery = { __typename?: 'Query', discountCodes?: Array<{ __typename?: 'DiscountCodeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, useBefore?: any | null, title: string, description?: string | null, discount: number } | null> | null };

export type CreateDiscountCodeMutationVariables = Exact<{
  input: CreateDiscountCodeInput;
}>;


export type CreateDiscountCodeMutation = { __typename?: 'Mutation', createDiscountCode: { __typename?: 'DiscountCodeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, useBefore?: any | null, title: string, description?: string | null, discount: number } };

export type UpdateDiscountCodeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateDiscountCodeInput;
}>;


export type UpdateDiscountCodeMutation = { __typename?: 'Mutation', updateDiscountCode: { __typename?: 'DiscountCodeModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, useBefore?: any | null, title: string, description?: string | null, discount: number } };

export type DeleteDiscountCodeMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteDiscountCodeMutation = { __typename?: 'Mutation', deleteDiscountCode: boolean };

export type BaseExtraFragment = { __typename?: 'ExtraModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null, type: string, when: string };

export type GetExtraQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetExtraQuery = { __typename?: 'Query', extra?: { __typename?: 'ExtraModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null, type: string, when: string } | null };

export type GetExtrasQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExtrasQuery = { __typename?: 'Query', extras?: Array<{ __typename?: 'ExtraModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null, type: string, when: string } | null> | null };

export type CreateExtraMutationVariables = Exact<{
  input: CreateExtraInput;
}>;


export type CreateExtraMutation = { __typename?: 'Mutation', createExtra: { __typename?: 'ExtraModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null, type: string, when: string } };

export type UpdateExtraMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateExtraInput;
}>;


export type UpdateExtraMutation = { __typename?: 'Mutation', updateExtra: { __typename?: 'ExtraModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null, type: string, when: string } };

export type DeleteExtraMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteExtraMutation = { __typename?: 'Mutation', deleteExtra: boolean };

export type BaseSubscriptionFragment = { __typename?: 'SubscriptionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null };

export type GetSubscriptionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetSubscriptionQuery = { __typename?: 'Query', subscription?: { __typename?: 'SubscriptionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null } | null };

export type GetSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSubscriptionsQuery = { __typename?: 'Query', subscriptions?: Array<{ __typename?: 'SubscriptionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null } | null> | null };

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'SubscriptionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null } };

export type UpdateSubscriptionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateSubscriptionInput;
}>;


export type UpdateSubscriptionMutation = { __typename?: 'Mutation', updateSubscription: { __typename?: 'SubscriptionModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null } };

export type DeleteSubscriptionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteSubscriptionMutation = { __typename?: 'Mutation', deleteSubscription: boolean };

export type BaseTierFragment = { __typename?: 'TierModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null, Price: number, Frequency: string, Extends?: string | null, SubscriptionId: string };

export type GetTierQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetTierQuery = { __typename?: 'Query', tier?: { __typename?: 'TierModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null, Price: number, Frequency: string, Extends?: string | null, SubscriptionId: string } | null };

export type GetTiersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTiersQuery = { __typename?: 'Query', tiers?: Array<{ __typename?: 'TierModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null, Price: number, Frequency: string, Extends?: string | null, SubscriptionId: string } | null> | null };

export type CreateTierMutationVariables = Exact<{
  input: CreateTierInput;
}>;


export type CreateTierMutation = { __typename?: 'Mutation', createTier: { __typename?: 'TierModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null, Price: number, Frequency: string, Extends?: string | null, SubscriptionId: string } };

export type UpdateTierMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateTierInput;
}>;


export type UpdateTierMutation = { __typename?: 'Mutation', updateTier: { __typename?: 'TierModel', id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, title: string, description?: string | null, Price: number, Frequency: string, Extends?: string | null, SubscriptionId: string } };

export type DeleteTierMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteTierMutation = { __typename?: 'Mutation', deleteTier: boolean };

export type BaseIdentityFragment = { __typename?: 'Identity', domain: string, user: { __typename?: 'User', id: string, name: string, email: string, latestTerms: string } };

export type GetIdentitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIdentitiesQuery = { __typename?: 'Query', identities: { __typename?: 'Identity', domain: string, user: { __typename?: 'User', id: string, name: string, email: string, latestTerms: string } } };

export type BaseUserFragment = { __typename?: 'UserModel', id: string, name: string, email: string, createdAt: any, updatedAt: any };

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetUserQuery = { __typename?: 'Query', user?: { __typename?: 'UserModel', name: string, id: string, email: string, createdAt: any, updatedAt: any, address?: Array<{ __typename?: 'AddressModel', addressType: string, city: string, id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, company: string, address: string, extra: string, province: string, postalCode: string } | null> | null, companies?: Array<{ __typename?: 'CompanyModel', name: string } | null> | null, subscriptionTiers?: Array<{ __typename?: 'TierModel', title: string, description?: string | null } | null> | null } | null };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'UserModel', name: string, id: string, email: string, createdAt: any, updatedAt: any, address?: Array<{ __typename?: 'AddressModel', addressType: string, city: string, id: string, createdAt: any, updatedAt: any, deletedAt?: any | null, company: string, address: string, extra: string, province: string, postalCode: string } | null> | null, companies?: Array<{ __typename?: 'CompanyModel', name: string } | null> | null, subscriptionTiers?: Array<{ __typename?: 'TierModel', title: string, description?: string | null } | null> | null } | null> | null };

export type GetUserFormDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetUserFormDataQuery = { __typename?: 'Query', user?: { __typename?: 'UserModel', name: string } | null };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserModel', id: string, name: string, email: string, createdAt: any, updatedAt: any } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserModel', id: string, name: string, email: string, createdAt: any, updatedAt: any } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type AddAddressToUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AddAddressToUserMutation = { __typename?: 'Mutation', addAddressToUser?: boolean | null };

export type AddCompanyToUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AddCompanyToUserMutation = { __typename?: 'Mutation', addCompanyToUser?: boolean | null };

export type AcceptTermsForUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type AcceptTermsForUserMutation = { __typename?: 'Mutation', acceptTermsForUser?: boolean | null };

export type RemoveAddressFromUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type RemoveAddressFromUserMutation = { __typename?: 'Mutation', removeAddressFromUser?: boolean | null };

export type RemoveCompanyFromUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type RemoveCompanyFromUserMutation = { __typename?: 'Mutation', removeCompanyFromUser?: boolean | null };

export type UnacceptTermsForUserMutationVariables = Exact<{
  relation: Scalars['ID']['input'];
  record: Scalars['ID']['input'];
}>;


export type UnacceptTermsForUserMutation = { __typename?: 'Mutation', unacceptTermsForUser?: boolean | null };

export const BaseCompanyFragmentDoc = gql`
    fragment BaseCompany on CompanyModel {
  id
  name
  createdAt
  updatedAt
}
    `;
export const BaseMerchantThemeFragmentDoc = gql`
    fragment BaseMerchantTheme on MerchantThemeModel {
  id
  createdAt
  updatedAt
  deletedAt
  companyLogo
  primaryColor
  secondaryColor
  isFormal
}
    `;
export const BaseAddressFragmentDoc = gql`
    fragment BaseAddress on AddressModel {
  id
  createdAt
  updatedAt
  deletedAt
  addressType
  company
  address
  extra
  city
  province
  postalCode
}
    `;
export const BaseContactFragmentDoc = gql`
    fragment BaseContact on ContactModel {
  id
  createdAt
  updatedAt
  deletedAt
  firstName
  middleName
  lastName
  position
  email
  phone
}
    `;
export const BaseBenefitFragmentDoc = gql`
    fragment BaseBenefit on BenefitModel {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
}
    `;
export const BaseConditionFragmentDoc = gql`
    fragment BaseCondition on ConditionModel {
  id
  createdAt
  updatedAt
  deletedAt
  title
  condition
}
    `;
export const BaseDiscountCodeFragmentDoc = gql`
    fragment BaseDiscountCode on DiscountCodeModel {
  id
  createdAt
  updatedAt
  deletedAt
  useBefore
  title
  description
  discount
}
    `;
export const BaseExtraFragmentDoc = gql`
    fragment BaseExtra on ExtraModel {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
  type
  when
}
    `;
export const BaseSubscriptionFragmentDoc = gql`
    fragment BaseSubscription on SubscriptionModel {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
}
    `;
export const BaseTierFragmentDoc = gql`
    fragment BaseTier on TierModel {
  id
  createdAt
  updatedAt
  deletedAt
  title
  description
  Price
  Frequency
  Extends
  SubscriptionId
}
    `;
export const BaseIdentityFragmentDoc = gql`
    fragment BaseIdentity on Identity {
  domain
  user {
    id
    name
    email
    latestTerms
  }
}
    `;
export const BaseUserFragmentDoc = gql`
    fragment BaseUser on UserModel {
  id
  name
  email
  createdAt
  updatedAt
}
    `;
export const GetCompanyDocument = gql`
    query GetCompany($id: ID!) {
  company(id: $id) {
    ...BaseCompany
  }
}
    ${BaseCompanyFragmentDoc}`;
export const GetCompaniesDocument = gql`
    query GetCompanies {
  companies {
    ...BaseCompany
  }
}
    ${BaseCompanyFragmentDoc}`;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    ...BaseCompany
  }
}
    ${BaseCompanyFragmentDoc}`;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($id: ID!, $input: UpdateCompanyInput!) {
  updateCompany(id: $id, input: $input) {
    ...BaseCompany
  }
}
    ${BaseCompanyFragmentDoc}`;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: ID!) {
  deleteCompany(id: $id)
}
    `;
export const AddCocToCompanyDocument = gql`
    mutation AddCocToCompany($relation: ID!, $record: ID!) {
  addCocToCompany(relation: $relation, record: $record)
}
    `;
export const AddAddressToCompanyDocument = gql`
    mutation AddAddressToCompany($relation: ID!, $record: ID!) {
  addAddressToCompany(relation: $relation, record: $record)
}
    `;
export const AddThemeToCompanyDocument = gql`
    mutation AddThemeToCompany($relation: ID!, $record: ID!) {
  addThemeToCompany(relation: $relation, record: $record)
}
    `;
export const AddContactToCompanyDocument = gql`
    mutation AddContactToCompany($relation: ID!, $record: ID!) {
  addContactToCompany(relation: $relation, record: $record)
}
    `;
export const RemoveCocFromCompanyDocument = gql`
    mutation RemoveCocFromCompany($relation: ID!, $record: ID!) {
  removeCocFromCompany(relation: $relation, record: $record)
}
    `;
export const RemoveAddressFromCompanyDocument = gql`
    mutation RemoveAddressFromCompany($relation: ID!, $record: ID!) {
  removeAddressFromCompany(relation: $relation, record: $record)
}
    `;
export const RemoveThemeFromCompanyDocument = gql`
    mutation RemoveThemeFromCompany($relation: ID!, $record: ID!) {
  removeThemeFromCompany(relation: $relation, record: $record)
}
    `;
export const RemoveContactFromCompanyDocument = gql`
    mutation RemoveContactFromCompany($relation: ID!, $record: ID!) {
  removeContactFromCompany(relation: $relation, record: $record)
}
    `;
export const GetMerchantThemeDocument = gql`
    query GetMerchantTheme($id: ID!) {
  merchantTheme(id: $id) {
    ...BaseMerchantTheme
  }
}
    ${BaseMerchantThemeFragmentDoc}`;
export const GetMerchantThemesDocument = gql`
    query GetMerchantThemes {
  merchantThemes {
    ...BaseMerchantTheme
  }
}
    ${BaseMerchantThemeFragmentDoc}`;
export const CreateMerchantThemeDocument = gql`
    mutation CreateMerchantTheme($input: CreateMerchantThemeInput!) {
  createMerchantTheme(input: $input) {
    ...BaseMerchantTheme
  }
}
    ${BaseMerchantThemeFragmentDoc}`;
export const UpdateMerchantThemeDocument = gql`
    mutation UpdateMerchantTheme($id: ID!, $input: UpdateMerchantThemeInput!) {
  updateMerchantTheme(id: $id, input: $input) {
    ...BaseMerchantTheme
  }
}
    ${BaseMerchantThemeFragmentDoc}`;
export const DeleteMerchantThemeDocument = gql`
    mutation DeleteMerchantTheme($id: ID!) {
  deleteMerchantTheme(id: $id)
}
    `;
export const GetAddressDocument = gql`
    query GetAddress($id: ID!) {
  address(id: $id) {
    ...BaseAddress
  }
}
    ${BaseAddressFragmentDoc}`;
export const GetAddressesDocument = gql`
    query GetAddresses {
  addresses {
    ...BaseAddress
  }
}
    ${BaseAddressFragmentDoc}`;
export const CreateAddressDocument = gql`
    mutation CreateAddress($input: CreateAddressInput!) {
  createAddress(input: $input) {
    ...BaseAddress
  }
}
    ${BaseAddressFragmentDoc}`;
export const UpdateAddressDocument = gql`
    mutation UpdateAddress($id: ID!, $input: UpdateAddressInput!) {
  updateAddress(id: $id, input: $input) {
    ...BaseAddress
  }
}
    ${BaseAddressFragmentDoc}`;
export const DeleteAddressDocument = gql`
    mutation DeleteAddress($id: ID!) {
  deleteAddress(id: $id)
}
    `;
export const GetContactDocument = gql`
    query GetContact($id: ID!) {
  contact(id: $id) {
    ...BaseContact
  }
}
    ${BaseContactFragmentDoc}`;
export const GetContactsDocument = gql`
    query GetContacts {
  contacts {
    ...BaseContact
  }
}
    ${BaseContactFragmentDoc}`;
export const CreateContactDocument = gql`
    mutation CreateContact($input: CreateContactInput!) {
  createContact(input: $input) {
    ...BaseContact
  }
}
    ${BaseContactFragmentDoc}`;
export const UpdateContactDocument = gql`
    mutation UpdateContact($id: ID!, $input: UpdateContactInput!) {
  updateContact(id: $id, input: $input) {
    ...BaseContact
  }
}
    ${BaseContactFragmentDoc}`;
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: ID!) {
  deleteContact(id: $id)
}
    `;
export const GetBenefitDocument = gql`
    query GetBenefit($id: ID!) {
  benefit(id: $id) {
    ...BaseBenefit
  }
}
    ${BaseBenefitFragmentDoc}`;
export const GetBenefitsDocument = gql`
    query GetBenefits {
  benefits {
    ...BaseBenefit
  }
}
    ${BaseBenefitFragmentDoc}`;
export const CreateBenefitDocument = gql`
    mutation CreateBenefit($input: CreateBenefitInput!) {
  createBenefit(input: $input) {
    ...BaseBenefit
  }
}
    ${BaseBenefitFragmentDoc}`;
export const UpdateBenefitDocument = gql`
    mutation UpdateBenefit($id: ID!, $input: UpdateBenefitInput!) {
  updateBenefit(id: $id, input: $input) {
    ...BaseBenefit
  }
}
    ${BaseBenefitFragmentDoc}`;
export const DeleteBenefitDocument = gql`
    mutation DeleteBenefit($id: ID!) {
  deleteBenefit(id: $id)
}
    `;
export const GetConditionDocument = gql`
    query GetCondition($id: ID!) {
  condition(id: $id) {
    ...BaseCondition
  }
}
    ${BaseConditionFragmentDoc}`;
export const GetConditionsDocument = gql`
    query GetConditions {
  conditions {
    ...BaseCondition
  }
}
    ${BaseConditionFragmentDoc}`;
export const CreateConditionDocument = gql`
    mutation CreateCondition($input: CreateConditionInput!) {
  createCondition(input: $input) {
    ...BaseCondition
  }
}
    ${BaseConditionFragmentDoc}`;
export const UpdateConditionDocument = gql`
    mutation UpdateCondition($id: ID!, $input: UpdateConditionInput!) {
  updateCondition(id: $id, input: $input) {
    ...BaseCondition
  }
}
    ${BaseConditionFragmentDoc}`;
export const DeleteConditionDocument = gql`
    mutation DeleteCondition($id: ID!) {
  deleteCondition(id: $id)
}
    `;
export const GetDiscountCodeDocument = gql`
    query GetDiscountCode($id: ID!) {
  discountCode(id: $id) {
    ...BaseDiscountCode
  }
}
    ${BaseDiscountCodeFragmentDoc}`;
export const GetDiscountCodesDocument = gql`
    query GetDiscountCodes {
  discountCodes {
    ...BaseDiscountCode
  }
}
    ${BaseDiscountCodeFragmentDoc}`;
export const CreateDiscountCodeDocument = gql`
    mutation CreateDiscountCode($input: CreateDiscountCodeInput!) {
  createDiscountCode(input: $input) {
    ...BaseDiscountCode
  }
}
    ${BaseDiscountCodeFragmentDoc}`;
export const UpdateDiscountCodeDocument = gql`
    mutation UpdateDiscountCode($id: ID!, $input: UpdateDiscountCodeInput!) {
  updateDiscountCode(id: $id, input: $input) {
    ...BaseDiscountCode
  }
}
    ${BaseDiscountCodeFragmentDoc}`;
export const DeleteDiscountCodeDocument = gql`
    mutation DeleteDiscountCode($id: ID!) {
  deleteDiscountCode(id: $id)
}
    `;
export const GetExtraDocument = gql`
    query GetExtra($id: ID!) {
  extra(id: $id) {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const GetExtrasDocument = gql`
    query GetExtras {
  extras {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const CreateExtraDocument = gql`
    mutation CreateExtra($input: CreateExtraInput!) {
  createExtra(input: $input) {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const UpdateExtraDocument = gql`
    mutation UpdateExtra($id: ID!, $input: UpdateExtraInput!) {
  updateExtra(id: $id, input: $input) {
    ...BaseExtra
  }
}
    ${BaseExtraFragmentDoc}`;
export const DeleteExtraDocument = gql`
    mutation DeleteExtra($id: ID!) {
  deleteExtra(id: $id)
}
    `;
export const GetSubscriptionDocument = gql`
    query GetSubscription($id: ID!) {
  subscription(id: $id) {
    ...BaseSubscription
  }
}
    ${BaseSubscriptionFragmentDoc}`;
export const GetSubscriptionsDocument = gql`
    query GetSubscriptions {
  subscriptions {
    ...BaseSubscription
  }
}
    ${BaseSubscriptionFragmentDoc}`;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    ...BaseSubscription
  }
}
    ${BaseSubscriptionFragmentDoc}`;
export const UpdateSubscriptionDocument = gql`
    mutation UpdateSubscription($id: ID!, $input: UpdateSubscriptionInput!) {
  updateSubscription(id: $id, input: $input) {
    ...BaseSubscription
  }
}
    ${BaseSubscriptionFragmentDoc}`;
export const DeleteSubscriptionDocument = gql`
    mutation DeleteSubscription($id: ID!) {
  deleteSubscription(id: $id)
}
    `;
export const GetTierDocument = gql`
    query GetTier($id: ID!) {
  tier(id: $id) {
    ...BaseTier
  }
}
    ${BaseTierFragmentDoc}`;
export const GetTiersDocument = gql`
    query GetTiers {
  tiers {
    ...BaseTier
  }
}
    ${BaseTierFragmentDoc}`;
export const CreateTierDocument = gql`
    mutation CreateTier($input: CreateTierInput!) {
  createTier(input: $input) {
    id
    createdAt
    updatedAt
    deletedAt
    title
    description
    Price
    Frequency
    Extends
    SubscriptionId
  }
}
    `;
export const UpdateTierDocument = gql`
    mutation UpdateTier($id: ID!, $input: UpdateTierInput!) {
  updateTier(id: $id, input: $input) {
    ...BaseTier
  }
}
    ${BaseTierFragmentDoc}`;
export const DeleteTierDocument = gql`
    mutation DeleteTier($id: ID!) {
  deleteTier(id: $id)
}
    `;
export const GetIdentitiesDocument = gql`
    query GetIdentities {
  identities {
    ...BaseIdentity
  }
}
    ${BaseIdentityFragmentDoc}`;
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
  user(id: $id) {
    ...BaseUser
    name
    address {
      addressType
      city
      ...BaseAddress
    }
    companies {
      name
    }
    subscriptionTiers {
      title
      description
    }
  }
}
    ${BaseUserFragmentDoc}
${BaseAddressFragmentDoc}`;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    ...BaseUser
    name
    address {
      addressType
      city
      ...BaseAddress
    }
    companies {
      name
    }
    subscriptionTiers {
      title
      description
    }
  }
}
    ${BaseUserFragmentDoc}
${BaseAddressFragmentDoc}`;
export const GetUserFormDataDocument = gql`
    query GetUserFormData($id: ID!) {
  user(id: $id) {
    name
  }
}
    `;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    ...BaseUser
  }
}
    ${BaseUserFragmentDoc}`;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $input: UpdateUserInput!) {
  updateUser(id: $id, input: $input) {
    ...BaseUser
  }
}
    ${BaseUserFragmentDoc}`;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id)
}
    `;
export const AddAddressToUserDocument = gql`
    mutation AddAddressToUser($relation: ID!, $record: ID!) {
  addAddressToUser(relation: $relation, record: $record)
}
    `;
export const AddCompanyToUserDocument = gql`
    mutation AddCompanyToUser($relation: ID!, $record: ID!) {
  addCompanyToUser(relation: $relation, record: $record)
}
    `;
export const AcceptTermsForUserDocument = gql`
    mutation AcceptTermsForUser($relation: ID!, $record: ID!) {
  acceptTermsForUser(relation: $relation, record: $record)
}
    `;
export const RemoveAddressFromUserDocument = gql`
    mutation RemoveAddressFromUser($relation: ID!, $record: ID!) {
  removeAddressFromUser(relation: $relation, record: $record)
}
    `;
export const RemoveCompanyFromUserDocument = gql`
    mutation RemoveCompanyFromUser($relation: ID!, $record: ID!) {
  removeCompanyFromUser(relation: $relation, record: $record)
}
    `;
export const UnacceptTermsForUserDocument = gql`
    mutation UnacceptTermsForUser($relation: ID!, $record: ID!) {
  unacceptTermsForUser(relation: $relation, record: $record)
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    GetCompany(variables: GetCompanyQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCompanyQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCompanyQuery>(GetCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCompany', 'query');
    },
    GetCompanies(variables?: GetCompaniesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCompaniesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCompaniesQuery>(GetCompaniesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCompanies', 'query');
    },
    CreateCompany(variables: CreateCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateCompanyMutation>(CreateCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateCompany', 'mutation');
    },
    UpdateCompany(variables: UpdateCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateCompanyMutation>(UpdateCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateCompany', 'mutation');
    },
    DeleteCompany(variables: DeleteCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteCompanyMutation>(DeleteCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteCompany', 'mutation');
    },
    AddCocToCompany(variables: AddCocToCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddCocToCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddCocToCompanyMutation>(AddCocToCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddCocToCompany', 'mutation');
    },
    AddAddressToCompany(variables: AddAddressToCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddAddressToCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddAddressToCompanyMutation>(AddAddressToCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddAddressToCompany', 'mutation');
    },
    AddThemeToCompany(variables: AddThemeToCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddThemeToCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddThemeToCompanyMutation>(AddThemeToCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddThemeToCompany', 'mutation');
    },
    AddContactToCompany(variables: AddContactToCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddContactToCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddContactToCompanyMutation>(AddContactToCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddContactToCompany', 'mutation');
    },
    RemoveCocFromCompany(variables: RemoveCocFromCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveCocFromCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveCocFromCompanyMutation>(RemoveCocFromCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveCocFromCompany', 'mutation');
    },
    RemoveAddressFromCompany(variables: RemoveAddressFromCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveAddressFromCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveAddressFromCompanyMutation>(RemoveAddressFromCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveAddressFromCompany', 'mutation');
    },
    RemoveThemeFromCompany(variables: RemoveThemeFromCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveThemeFromCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveThemeFromCompanyMutation>(RemoveThemeFromCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveThemeFromCompany', 'mutation');
    },
    RemoveContactFromCompany(variables: RemoveContactFromCompanyMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveContactFromCompanyMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveContactFromCompanyMutation>(RemoveContactFromCompanyDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveContactFromCompany', 'mutation');
    },
    GetMerchantTheme(variables: GetMerchantThemeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMerchantThemeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMerchantThemeQuery>(GetMerchantThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetMerchantTheme', 'query');
    },
    GetMerchantThemes(variables?: GetMerchantThemesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetMerchantThemesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetMerchantThemesQuery>(GetMerchantThemesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetMerchantThemes', 'query');
    },
    CreateMerchantTheme(variables: CreateMerchantThemeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateMerchantThemeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateMerchantThemeMutation>(CreateMerchantThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateMerchantTheme', 'mutation');
    },
    UpdateMerchantTheme(variables: UpdateMerchantThemeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateMerchantThemeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMerchantThemeMutation>(UpdateMerchantThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateMerchantTheme', 'mutation');
    },
    DeleteMerchantTheme(variables: DeleteMerchantThemeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteMerchantThemeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteMerchantThemeMutation>(DeleteMerchantThemeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteMerchantTheme', 'mutation');
    },
    GetAddress(variables: GetAddressQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAddressQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAddressQuery>(GetAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAddress', 'query');
    },
    GetAddresses(variables?: GetAddressesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetAddressesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetAddressesQuery>(GetAddressesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetAddresses', 'query');
    },
    CreateAddress(variables: CreateAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateAddressMutation>(CreateAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateAddress', 'mutation');
    },
    UpdateAddress(variables: UpdateAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateAddressMutation>(UpdateAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateAddress', 'mutation');
    },
    DeleteAddress(variables: DeleteAddressMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteAddressMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteAddressMutation>(DeleteAddressDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteAddress', 'mutation');
    },
    GetContact(variables: GetContactQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetContactQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetContactQuery>(GetContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetContact', 'query');
    },
    GetContacts(variables?: GetContactsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetContactsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetContactsQuery>(GetContactsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetContacts', 'query');
    },
    CreateContact(variables: CreateContactMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateContactMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateContactMutation>(CreateContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateContact', 'mutation');
    },
    UpdateContact(variables: UpdateContactMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateContactMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateContactMutation>(UpdateContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateContact', 'mutation');
    },
    DeleteContact(variables: DeleteContactMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteContactMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteContactMutation>(DeleteContactDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteContact', 'mutation');
    },
    GetBenefit(variables: GetBenefitQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitQuery>(GetBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBenefit', 'query');
    },
    GetBenefits(variables?: GetBenefitsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBenefitsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBenefitsQuery>(GetBenefitsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetBenefits', 'query');
    },
    CreateBenefit(variables: CreateBenefitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateBenefitMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateBenefitMutation>(CreateBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateBenefit', 'mutation');
    },
    UpdateBenefit(variables: UpdateBenefitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateBenefitMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateBenefitMutation>(UpdateBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateBenefit', 'mutation');
    },
    DeleteBenefit(variables: DeleteBenefitMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteBenefitMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteBenefitMutation>(DeleteBenefitDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteBenefit', 'mutation');
    },
    GetCondition(variables: GetConditionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetConditionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetConditionQuery>(GetConditionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetCondition', 'query');
    },
    GetConditions(variables?: GetConditionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetConditionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetConditionsQuery>(GetConditionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetConditions', 'query');
    },
    CreateCondition(variables: CreateConditionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateConditionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateConditionMutation>(CreateConditionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateCondition', 'mutation');
    },
    UpdateCondition(variables: UpdateConditionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateConditionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateConditionMutation>(UpdateConditionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateCondition', 'mutation');
    },
    DeleteCondition(variables: DeleteConditionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteConditionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteConditionMutation>(DeleteConditionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteCondition', 'mutation');
    },
    GetDiscountCode(variables: GetDiscountCodeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetDiscountCodeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDiscountCodeQuery>(GetDiscountCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetDiscountCode', 'query');
    },
    GetDiscountCodes(variables?: GetDiscountCodesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetDiscountCodesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetDiscountCodesQuery>(GetDiscountCodesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetDiscountCodes', 'query');
    },
    CreateDiscountCode(variables: CreateDiscountCodeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateDiscountCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateDiscountCodeMutation>(CreateDiscountCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateDiscountCode', 'mutation');
    },
    UpdateDiscountCode(variables: UpdateDiscountCodeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateDiscountCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateDiscountCodeMutation>(UpdateDiscountCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateDiscountCode', 'mutation');
    },
    DeleteDiscountCode(variables: DeleteDiscountCodeMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteDiscountCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteDiscountCodeMutation>(DeleteDiscountCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteDiscountCode', 'mutation');
    },
    GetExtra(variables: GetExtraQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetExtraQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetExtraQuery>(GetExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetExtra', 'query');
    },
    GetExtras(variables?: GetExtrasQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetExtrasQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetExtrasQuery>(GetExtrasDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetExtras', 'query');
    },
    CreateExtra(variables: CreateExtraMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateExtraMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateExtraMutation>(CreateExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateExtra', 'mutation');
    },
    UpdateExtra(variables: UpdateExtraMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateExtraMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateExtraMutation>(UpdateExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateExtra', 'mutation');
    },
    DeleteExtra(variables: DeleteExtraMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteExtraMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteExtraMutation>(DeleteExtraDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteExtra', 'mutation');
    },
    GetSubscription(variables: GetSubscriptionQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSubscriptionQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSubscriptionQuery>(GetSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSubscription', 'query');
    },
    GetSubscriptions(variables?: GetSubscriptionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSubscriptionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSubscriptionsQuery>(GetSubscriptionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetSubscriptions', 'query');
    },
    CreateSubscription(variables: CreateSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateSubscriptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateSubscriptionMutation>(CreateSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateSubscription', 'mutation');
    },
    UpdateSubscription(variables: UpdateSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateSubscriptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateSubscriptionMutation>(UpdateSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateSubscription', 'mutation');
    },
    DeleteSubscription(variables: DeleteSubscriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteSubscriptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteSubscriptionMutation>(DeleteSubscriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteSubscription', 'mutation');
    },
    GetTier(variables: GetTierQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTierQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTierQuery>(GetTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTier', 'query');
    },
    GetTiers(variables?: GetTiersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetTiersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetTiersQuery>(GetTiersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetTiers', 'query');
    },
    CreateTier(variables: CreateTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateTierMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateTierMutation>(CreateTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateTier', 'mutation');
    },
    UpdateTier(variables: UpdateTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateTierMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateTierMutation>(UpdateTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateTier', 'mutation');
    },
    DeleteTier(variables: DeleteTierMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteTierMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteTierMutation>(DeleteTierDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteTier', 'mutation');
    },
    GetIdentities(variables?: GetIdentitiesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetIdentitiesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetIdentitiesQuery>(GetIdentitiesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetIdentities', 'query');
    },
    GetUser(variables: GetUserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserQuery>(GetUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUser', 'query');
    },
    GetUsers(variables?: GetUsersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUsersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUsersQuery>(GetUsersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUsers', 'query');
    },
    GetUserFormData(variables: GetUserFormDataQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUserFormDataQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserFormDataQuery>(GetUserFormDataDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'GetUserFormData', 'query');
    },
    CreateUser(variables: CreateUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<CreateUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUserMutation>(CreateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'CreateUser', 'mutation');
    },
    UpdateUser(variables: UpdateUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UpdateUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateUserMutation>(UpdateUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UpdateUser', 'mutation');
    },
    DeleteUser(variables: DeleteUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<DeleteUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteUserMutation>(DeleteUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'DeleteUser', 'mutation');
    },
    AddAddressToUser(variables: AddAddressToUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddAddressToUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddAddressToUserMutation>(AddAddressToUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddAddressToUser', 'mutation');
    },
    AddCompanyToUser(variables: AddCompanyToUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AddCompanyToUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AddCompanyToUserMutation>(AddCompanyToUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AddCompanyToUser', 'mutation');
    },
    AcceptTermsForUser(variables: AcceptTermsForUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<AcceptTermsForUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AcceptTermsForUserMutation>(AcceptTermsForUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'AcceptTermsForUser', 'mutation');
    },
    RemoveAddressFromUser(variables: RemoveAddressFromUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveAddressFromUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveAddressFromUserMutation>(RemoveAddressFromUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveAddressFromUser', 'mutation');
    },
    RemoveCompanyFromUser(variables: RemoveCompanyFromUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<RemoveCompanyFromUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemoveCompanyFromUserMutation>(RemoveCompanyFromUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RemoveCompanyFromUser', 'mutation');
    },
    UnacceptTermsForUser(variables: UnacceptTermsForUserMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<UnacceptTermsForUserMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UnacceptTermsForUserMutation>(UnacceptTermsForUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'UnacceptTermsForUser', 'mutation');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;