import { Check } from '@mui/icons-material';
import React, { FC, JSX } from 'react';
import Button, { ButtonProps } from './Button';


/**
 *
 * @param props
 * @constructor
 */
const AcceptButton: FC<ButtonProps> = (props): JSX.Element => (
    <Button { ...props } textColor={ 'white' } bgColor={ 'primary' } Icon={ Check }>
        Accept
    </Button>
);

export default AcceptButton;