import React, { FC } from 'react';
import { classNames } from '../../modules/Parse/String';
import { ListType } from './TextTypes';


const List: FC<ListType> = ({ state = 'bullet', items, className, ...props }) => <ul { ...props }
    className={ classNames('text-neutral text-sm my-2 list-disc list-outside pl-4',
        state == 'bullet' ?'list-desc' :(state == 'numerical' ?'list-decimal' :'list-[lower-alpha]'),
        className
    ) }
>
    { items.map((item, i) => <li key={ i }>{ item }</li>) }
</ul>;


export default List;