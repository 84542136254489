import React from 'react'
import {Business} from "@mui/icons-material";
import {Link} from "react-router-dom";

interface HeaderComponentProps {
    title: string
}

const RegisterPageHeaderComponent: React.FC<HeaderComponentProps> = ({title}) => {

    return <div className='flex flex-col items-center justify-center relative'>
        <p className={`text-2xl font-semibold text-center ${title.includes('Company') ? 'break-word max-w-min' : ''}`}>
            {title}
        </p>
        <div className='border rounded-full h-20 w-20 flex items-center justify-center my-10'>
            <Business/>
        </div>
        <Link
            to='/'
            className='absolute top-5 right-2 text-sm text-black/80 hover:underline decoration-blue-300 hover:text-blue-300 transition-all'>
            Logout
        </Link>
    </div>
}

export default RegisterPageHeaderComponent