import React, { FC, JSX, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BaseCompanyFragment } from '../../../gql/schema';
import BasePage from '../../../layout/BasePage';
import { CreateButton, DeleteButton } from '../../../lib/samfe/components/Button';
import List from '../../../lib/samfe/components/Text/List';
import useCompany from '../../../resources/Company/useCompany';


const UserListTest: FC = (): JSX.Element => {

    const company = useCompany();
    const [ companies, setCompanies ] = useState<BaseCompanyFragment[]>([]);

    useEffect(() => {
        company.getList({}).then(setCompanies);
    }, []);

    const handleDelete = (id: string) => {
        company.destroy({ id }).then(() => company.getList({}).then(setCompanies));
    };
    return <BasePage title={ 'Company List Test' } navbarTop={ {
        showBackButton: true
    } }>

        <Link to={ '/testing/company-form' }><CreateButton/></Link>
        <List items={ companies.map((company, i) => <div key={ i } className={ 'grid grid-cols-6 p-4 hover:bg-primary-100 rounded' }>
            <Link className={ 'col-span-5' } to={ `/testing/company-form/${ company.id }` }>{ company.name }</Link>
            <div><DeleteButton state={ 'primary' } small={ true } onClick={ () => handleDelete(company.id) }/></div>
        </div>) }/>
    </BasePage>;
};
export default UserListTest;