import {
    BaseCompanyFragment,
    CreateCompanyDocument,
    CreateCompanyMutation,
    CreateCompanyMutationVariables,
    DeleteCompanyDocument,
    DeleteCompanyMutation,
    DeleteCompanyMutationVariables,
    GetCompaniesDocument,
    GetCompaniesQuery,
    GetCompaniesQueryVariables,
    GetCompanyDocument,
    GetCompanyQuery,
    GetCompanyQueryVariables,
    UpdateCompanyDocument,
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
} from '../../gql/schema';
import useGraphQL from '../../lib/samfe/modules/graphql/useGraphQL';


const useCompany = () => {
    const {querySingle, queryList, mutate, destroy} = useGraphQL<BaseCompanyFragment>();
    return {
        getSingle: querySingle<GetCompanyQueryVariables, GetCompanyQuery>(GetCompanyDocument, 'company'),
        getList: queryList<GetCompaniesQueryVariables, GetCompaniesQuery>(GetCompaniesDocument, 'companies'),
        create: mutate<CreateCompanyMutationVariables, CreateCompanyMutation>(CreateCompanyDocument, 'createCompany'),
        update: mutate<UpdateCompanyMutationVariables, UpdateCompanyMutation>(UpdateCompanyDocument, 'updateCompany'),
        destroy: destroy<DeleteCompanyMutationVariables, DeleteCompanyMutation>(DeleteCompanyDocument, 'deleteCompany')
    };
};

export default useCompany;