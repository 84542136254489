import React, { FC, JSX } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateUserInput, CreateUserMutationVariables, UserModel } from '../../../gql/schema';
import BasePage from '../../../layout/BasePage';
import Form, { useForm } from '../../../lib/samfe/modules/Form';
import useSchema from '../../../lib/samfe/modules/Form/useSchema';
import Yup from '../../../lib/samfe/modules/Form/Yup';
import useUser from '../../../resources/auth/User/useUser';


const UserFormCreateTest: FC = (): JSX.Element => {

    const navigate = useNavigate();
    const user = useUser();

    const { validationSchema } = useSchema<CreateUserInput>({
        // @fixme cannot edit on update, only last keystroke is set + initial
        name: Yup.string()
            .controlType('input')
            .fieldConfig({
                label: 'Naam'
            })
            .inputType('text')
            .required()
    });

    const form = useForm<
        CreateUserInput,
        CreateUserMutationVariables,
        UserModel
    >({
        config: {},
        http: {
            formAction: user.create,
            method: 'POST',
            onSuccess: () => {
                navigate('/testing/user-list');
            }
        },
        validationSchema

    });


    return <BasePage title={ 'User Create Test Form' } navbarTop={ {
        showBackButton: true
    } }>
        <Form { ...form } withSaveButton={ true }/>
    </BasePage>;
};
export default UserFormCreateTest;