import React, {Dispatch, SetStateAction} from 'react'
import {Tabs, TabsList, TabsTrigger} from "@/components/ui/tabs";
import PricingTooltipComponent from "./PricingTooltipComponent";

interface PricingPeriodTabComponentProps {
    period: string[]
    currentPricingPeriod: string
    setCurrentPricingPeriod: Dispatch<SetStateAction<string>>
}

const PricingPeriodTabComponent: React.FC<PricingPeriodTabComponentProps> = ({
    period,
    currentPricingPeriod,
    setCurrentPricingPeriod
}) => {

    function handleChangePeriod(value: string) {
        setCurrentPricingPeriod(value)
    }

    return (

        <Tabs className={'my-10'} defaultValue={period[0]} value={currentPricingPeriod}
            onValueChange={(value) => handleChangePeriod(value)}>

            <TabsList className={'grid grid-cols-2 w-full gap-x-5'}>

                {period.map((value, index) => {
                    return <React.Fragment key={index}>

                        {index === 0 ?

                            <TabsTrigger value={value}
                                key={index}>
                                <span className={'capitalize'}>{value}</span>
                            </TabsTrigger>
                            :
                            <TabsTrigger value={value}
                                key={index}>
                                <PricingTooltipComponent>
                                    <span className={'capitalize'}>{value}</span>
                                </PricingTooltipComponent>
                            </TabsTrigger>
                        }
                    </React.Fragment>
                })}
            </TabsList>
        </Tabs>

    )
}

export default PricingPeriodTabComponent