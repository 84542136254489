import { useAuth0 } from '@auth0/auth0-react';
import React, { FC, JSX, ReactNode } from 'react';
import NavbarBottom, { ExtendNavBarBottomProps } from '../lib/samfe/components/Navbar/NavbarBottom';
import NavbarTop, { ExtendNavBarTopProps } from '../lib/samfe/components/Navbar/NavbarTop';
import useAuthGuard from '../lib/samfe/modules/Auth0/useAuthGuard';


type Props = {
    title: string
    children: ReactNode
    navbarTop?: ExtendNavBarTopProps
    navbarBottom?: ExtendNavBarBottomProps
}
const BasePage: FC<Props> = ({ title, children, navbarTop, navbarBottom }): JSX.Element => {
    const { isLoading } = useAuth0();
    useAuthGuard();

    return <>
        <NavbarTop title={ title } { ...navbarTop } />
        <main className={ 'overflow-y-auto h-[calc(100vh-6rem)] p-4' }>
            { isLoading ?<>Loading</> :children }
        </main>
        <NavbarBottom { ...navbarBottom } />
    </>;
};
export default BasePage;