import React, { FC, JSX } from 'react';
import { Link } from 'react-router-dom';
import BasePage from '../../layout/BasePage';
import List from '../../lib/samfe/components/Text/List';


const Styling: FC = (): JSX.Element => {

    /**
     * Add key to Link.
     */
    const link = (to: string, name: string): JSX.Element => <Link key={ to } to={ to }>{ name }</Link>;

    return <BasePage title={ 'Styling' } navbarTop={ {
        showBackButton: true
    } }>
        <List state={ 'bullet' } items={ [
            link('buttons', 'Buttons'),
            link('typography', 'Typography'),
            link('form-fields', 'Form fields'),
            link('overview-list', 'Overview list')
        ] }/>
    </BasePage>;
};
export default Styling;