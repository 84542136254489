import React, { FC } from 'react';
import { JSX } from 'react/index';
import { classNames } from '../../modules/Parse/String';
import { ImageProps } from './ImageTypes';


const DetailImage: FC<ImageProps> = (props): JSX.Element => {
    return <img { ...props }
        className={ classNames('h-10 rounded-full bg-primary-accent', props.className) }
        alt={ props.alt }
    />;
};

export default DetailImage;