import {useAuth0} from '@auth0/auth0-react';
import React, {useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import {IS_PRODUCTION} from './config/variables';
import useIdToken from './lib/samfe/modules/Auth0/useIdToken';
import Conditions from './pages/Conditions/Conditions';
import Home from './pages/Home';
import Login from './pages/Login/Login';
import Styling from './pages/Styling';
import Buttons from './pages/Styling/Buttons';
import FormFields from './pages/Styling/FormFields';
import TestOverviewList from './pages/Styling/TestOverviewList';
import Typography from './pages/Styling/Typography';
import Testing from './pages/Testing';
import CompanyFormCreateTest from './pages/Testing/CompanyTest/CompanyFormCreateTest';
import CompanyFormUpdateTest from './pages/Testing/CompanyTest/CompanyFormUpdateTest';
import CompanyListTest from './pages/Testing/CompanyTest/CompanyListTest';
import TestOnboarding from './pages/Testing/TestOnboarding';
import UserFormCreateTest from './pages/Testing/UserTest/UserFormCreateTest';
import UserFormUpdateTest from './pages/Testing/UserTest/UserFormUpdateTest';
import UserListTest from './pages/Testing/UserTest/UserListTest';
import TwoFactor from './pages/TwoFactor/TwoFactor';
import CreateCompanyScreen from './stories/RegistrationPage/CreateCompanyScreen';
import CreateProfileScreen from './stories/RegistrationPage/CreateProfileScreen';
import SubscriptionPage from "./stories/SubscriptionPage/SubscriptionPage";
import CheckOutPage from "./stories/CheckOutPage/CheckOutPage";


function App() {
    const {isAuthenticated, getIdTokenClaims, isLoading} = useAuth0();
    const {setIdToken} = useIdToken();

    useEffect(() => {
        if (isLoading) {
            return;
        }
        if (isAuthenticated) {
            getIdTokenClaims().then((res) => {
                // @todo validate token by Backend.
                setIdToken(res?.__raw);
            });
        }
    }, [isAuthenticated, isLoading]);

    return (
        <div>
            <Routes>
                <Route path={'/'} element={<Home/>}/>

                <Route path={'/login'} element={<Login/>}/>
                <Route path={'/two-factor'} element={<TwoFactor/>}/>
                <Route path={'/terms-and-conditions'} element={<Conditions/>}/>

                {/* Development only*/}
                {!IS_PRODUCTION && (
                    <>
                        {/* COMPONENT STYLING */}
                        <Route path={'/styling'} element={<Styling/>}/>
                        <Route path={'/styling/buttons'} element={<Buttons/>}/>
                        <Route path={'/styling/typography'} element={<Typography/>}/>
                        <Route path={'/styling/form-fields'} element={<FormFields/>}/>
                        <Route
                            path={'/styling/overview-list'}
                            element={<TestOverviewList/>}
                        />

                        {/* COMPONENT TESTING*/}
                        <Route path={'/testing'} element={<Testing/>}/>
                        <Route
                            path={'/testing/user-form'}
                            element={<UserFormCreateTest/>}
                        />
                        <Route
                            path={'/testing/user-form/:id'}
                            element={<UserFormUpdateTest/>}
                        />
                        <Route path={'/testing/user-list'} element={<UserListTest/>}/>

                        <Route
                            path={'/testing/company-form'}
                            element={<CompanyFormCreateTest/>}
                        />
                        <Route
                            path={'/testing/company-form/:id'}
                            element={<CompanyFormUpdateTest/>}
                        />
                        <Route
                            path={'/testing/company-list'}
                            element={<CompanyListTest/>}
                        />
                        <Route path={'/testing/onboarding'} element={<TestOnboarding/>}/>

                        <Route path={'/testProf'} element={<CreateProfileScreen/>}/>
                        <Route path={'/testComp'} element={<CreateCompanyScreen/>}/>
                        <Route path={'/testSub'} element={<SubscriptionPage/>}/>
                        <Route path={'/testCheckOut'} element={<CheckOutPage/>}/>
                    </>
                )}

                <Route path={'/test'} element={<SubscriptionPage/>}/>
            </Routes>
        </div>
    );
}


export default App;
