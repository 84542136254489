import { Lock } from '@mui/icons-material';
import React, { FC, JSX, useState } from 'react';
import OtpInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import useAuthGuard from '../../lib/samfe/modules/Auth0/useAuthGuard';


const TwoFactor: FC = (): JSX.Element => {

    useAuthGuard();
    const [ otp, setOtp ] = useState('');

    const submit = () => {
        // @todo
    };

    return <div className={ 'text-center' }>
        <h1 className={ 'mt-16 text-3xl font-bold text-neutral-900' }>Two Factor</h1>

        <div className={ 'w-24 h-24 rounded-full bg-primary-650 mx-auto my-12 text-white pt-5' }>
            <Lock className={ '!w-12 !h-12' }/>
        </div>

        <OtpInput
            containerStyle={ {
                justifyContent: 'space-around'
            } }
            shouldAutoFocus={ true }
            value={ otp }
            onChange={ setOtp }
            numInputs={ 6 }
            renderInput={ (props) => <input { ...props } className={ 'border border-neutral-400 rounded py-4 !w-10' }/> }
        />

        <button className={ 'my-12 py-4 px-8 rounded text-white bg-primary-650 w-full' } onClick={ submit }>Sign in</button>

        <Link to={ '/' }>
            <button className={ 'underline' }>Skip (dev only)</button>
        </Link>

    </div>;
};

export default TwoFactor;