/* eslint-disable no-unused-vars */

import { FormikValues } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { AnyObject, Maybe } from 'yup';
import { ExtendBaseModel, VariablesType } from '../graphql/useGraphQL';
import { getDefaultEmptyValue, isEmpty, SupportedPayloadType, SupportedPayloadValue } from './FieldSupport';
import { FormControlProps } from './FormControl';
import { FormControl, FormikConfig, FormProps } from './index';
import Yup from './Yup';


type ExtendFormik<Input extends object> = Omit<FormikConfig<Input>,
    'validateOnChange'|'enableReinitialize'|'initialTouched'|'onSubmit'|'initialValues'|'isSaved'|'setIsSaved'
>;

type Props<
    Input extends object,
    Variables extends VariablesType,
    Model extends ExtendBaseModel
> = ExtendFormik<Input>&{

    config: {
        id?: string
        idKey?: keyof Model
        parentId?: number,
        parentIdKey?: keyof Model,
        morphInitialValues?: (model?: Model) => FormikValues,
        morphPayload?: (values: FormikValues, currentDto: Input, initialValues?: FormikValues) => Input,
    }

    http: {
        method: 'POST'|'PUT'
        initialModel?: () => Promise<Model|undefined>,
        formAction: (variables: Variables) => Promise<Model|undefined>,
        onSuccess?: (model?: Model) => void
    }
}


/**
 *
 * @param validationSchema
 * @param onSuccess
 * @param initialized
 */
const useForm = <Input extends object, Variables extends VariablesType, Model extends ExtendBaseModel>({
    validationSchema,
    initialized,
    http,
    config
}: Props<Input, Variables, Model>): FormProps<Input> => {


    const [ initialValues, setInitialValues ] = useState<FormikValues|undefined>(undefined);
    const [ isSaved, setIsSaved ] = useState(false);
    const { isTest } = { isTest: false }; // @todo check if test is performed

    const initialModel = useMemo(async() => {
        if (!http.initialModel) {
            return undefined;
        }
        return await http.initialModel();
    }, []);


    const { formAction, onSuccess, method } = http;

    const {
        parentId,
        parentIdKey,

        id,
        idKey = 'id',

        morphPayload,
        morphInitialValues
    } = config;

    useEffect(() => {
        console.log('wagwan');
        initialModel?.then(model => {
            setInitialValues(morphInitialValues ?morphInitialValues(model) :model);
        });
    }, []);

    useEffect(() => {
        console.log('sej');
        if (isSaved) {
            setIsSaved(false);
        }
    }, [ isSaved ]);


    /**
     * Convert shape to object schema.
     */
    const yupFields = () => Object.keys(validationSchema.fields).reduce((yupFields, k) => ({
        ...yupFields,
        [k]: (validationSchema.fields as Yup.AnyObject)[k]
    }), {} as { [key: string]: Yup.ObjectSchema<Maybe<AnyObject>> });


    /**
     *
     * @param field
     */
    const fieldIsRequired = (field: Yup.ObjectSchema<Maybe<AnyObject>>) => {
        let requiredProperty: boolean = field.tests.find(test => test.OPTIONS?.name == 'required') !== undefined;
        // Get value for number or boolean.
        if (field.type == 'number' || field.type == 'boolean') {
            const parsedField = field as { [k: string]: any };
            requiredProperty = parsedField?.internalTests?.optionality !== undefined;
        }
        if (!isTest && (field.spec?.meta && field.spec?.meta['disabled'] /* || field.spec.meta['hidden'] */)) {
            return false;
        }
        return requiredProperty;
    };


    /**
     * Extract fields from object schema.
     */
    const fields = (): FormControlProps<Model, Input>[] => {

        const fieldsOfYup = yupFields();
        return Object.keys(fieldsOfYup).map(k => {
            const field = fieldsOfYup[k].spec;
            const meta = field.meta ?? {};
            const fields: FormControlProps<Model, Input> = {
                name: k,
                controlType: meta['controlType'],
                fieldConfig: meta['fieldConfig'],
                initialValue: initialValues ?initialValues[k] :meta['initialValue'],
                required: fieldIsRequired(fieldsOfYup[k]),
                disabled: false, // @fixme
                hidden: !isTest ?(meta['hidden']) :false,
                onChange: meta['onChange'],

                selectConfig: meta['selectConfig'], // Select config
                type: meta['inputType'],    // Input
                steps: meta['steps']        // Number steps
            };

            /**
             * Access min and max values.
             * Yup can validate, but input does not limit.
             * @todo less nested solution
             */
            const fieldDescription = validationSchema.describe().fields[k];
            if (validationSchema.describe().fields[k].type === 'number') {
                if ('tests' in fieldDescription) {
                    const numberControls = [ 'min', 'max' ];
                    fieldDescription.tests.forEach((test: any) => { // @fixme any type
                        if (test.name !== undefined && test.params !== undefined && Object.keys(test.params).length>0) {
                            if (numberControls.includes(test.name)) {
                                fields[test.name as 'min'|'max'] = test.params[test.name] as number;
                            }
                        }
                    });
                }
            }
            return fields;
        }).filter((field) => !field.hidden ?? !id);
    };


    /**
     *
     */
    const getInitialValues = () => {
        const yupFieldList = yupFields();

        const value = (k: string) => {
            const specMeta = yupFieldList[k].spec?.meta ?? {};
            return initialValues
                ?(initialValues[k] ?? '')
                // Default value for Formik, when no default is specified it is going to crash.
                :specMeta['initialValue'] ?? (initialized ?'' :undefined);
        };

        return Object.keys(yupFieldList).reduce((formikValues, k) => ({
            ...formikValues,
            [k]: value(k)
        }), {} as FormikValues);
    };


    const handleRes = (res: Model|undefined) => {
        if (res !== undefined && onSuccess) {
            setIsSaved(true);
            onSuccess(res);
        }
    };


    /**
     *
     * @param values
     */
    const handleCreate = async(values: FormikValues) => {
        // If empty -> remove property
        let input: { [k: string]: string|number|boolean } = Object.keys(values)
            .filter(k => !isEmpty(values[k]))
            .reduce((prev, k) => ({
                ...prev,
                [k]: values[k]
            }), {});

        if (parentIdKey && parentId) {
            input[parentIdKey as string] = parentId;
        }

        if (morphPayload) {
            input = morphPayload(values, input as Input) as {};
        }

        await formAction({ input: input as Input } as unknown as Variables).then(handleRes);
    };


    /**
     *
     * @param formikValues
     */
    const handleUpdate = async(formikValues: FormikValues) => {
        const values = { ...formikValues } as { [k: string]: SupportedPayloadType };
        const initialValues = getInitialValues();
        let input = Object.keys(values)
            //
            // // If empty and not changed -> remove property
            // .filter( k => Object.prototype.hasOwnProperty.call(initialValues, k) && !isEmpty( values[k] ) )
            //
            // // If not changed -> remove property
            // .filter( k => values[k] != initialValues[k] )

            .reduce((prev, k) => ({
                ...prev,
                // IF empty -> (IF string -> '' ELSE -> null)
                [k]: !isEmpty(values[k]) ?values[k] :getDefaultEmptyValue(typeof (values[k] as SupportedPayloadType) as SupportedPayloadValue)
            }), {}) as Input;

        if (morphPayload) {
            input = morphPayload(values, input, initialValues);
        }

        await formAction({ [idKey]: id, input: input as Input } as unknown as Variables).then(handleRes);
    };


    /**
     *
     * @param values
     */
    const onSubmit = async(values: FormikValues) => await method == 'PUT'
        ?handleUpdate(values)
        :handleCreate(values);


    return ({
        formikConfig: {
            id,
            initialValues: getInitialValues(),
            onSubmit,
            validationSchema,
            validateOnChange: true,
            enableReinitialize: true,
            isSaved,
            setIsSaved
        },

        formFields: fields().map(({
            controlType,
            name,
            fieldConfig,
            initialValue,
            onChange,
            hidden,
            required,
            disabled,

            selectConfig,

            type,
            min,
            max,
            steps
        }, i) => <FormControl

            key={ i }
            initialValue={ initialValue }
            controlType={ controlType }
            name={ name }
            hidden={ hidden }
            required={ required }
            disabled={ disabled }
            fieldConfig={ fieldConfig }
            onChange={ onChange }

            // input config
            { ...(controlType === 'input' && { type }) }
            { ...(controlType === 'input' && type === 'number' && { min: min, max: max, step: steps }) }

            // select config
            { ...(controlType === 'select' && { selectConfig }) }

            // checkbox/switch config
            { ...([ 'checkbox', 'switch' ].includes(controlType) && { initialValue: initialValue == true }) }

            // text area config
            // Nothing for now
        />)
    });
};

export default useForm;
