import { Add, Payments, People, Receipt, Settings, SvgIconComponent } from '@mui/icons-material';
import React, { FC, JSX } from 'react';
import { Link } from 'react-router-dom';
import { classNames } from '../../modules/Parse/String';


type Tab = {
    name: string,
    Icon: SvgIconComponent,
    href: string,
    current: boolean,
}

const tabsLeft: Tab[] = [
    { name: 'Payments', Icon: Payments, href: '#', current: true },
    { name: 'Subscriptions', Icon: Receipt, href: '#', current: false }
];

const tabsRight: Tab[] = [
    { name: 'Subscribers', Icon: People, href: '#', current: false },
    { name: 'Settings', Icon: Settings, href: '#', current: false }
];

export type ExtendNavBarBottomProps = {
    hideNavbar?: boolean
}

type Props = ExtendNavBarBottomProps&{}
const NavbarBottom: FC<Props> = ({ hideNavbar = false }): JSX.Element => {


    const tabItem = (tab: Tab, index: number) => <Link
        key={ tab.name }
        to={ tab.href }
        className={ classNames(
            tab.current ?'text-primary !font-[600]' :'text-gray-500 hover:text-secondary',
            index == 0 ?'pr-1' :'pl-1',
            'relative py-1 hover:bg-gray-50 focus:z-10'
        ) }
        aria-current={ tab.current ?'page' :undefined }
    >
        <tab.Icon/>
        <span className={ 'block text-[8px] -mt-1' }>{ tab.name }</span>
    </Link>;

    return <>
        { !hideNavbar && <>
            <div className={ 'my-[2.9rem]' }></div>
            <div className={ 'fixed px-4 border-t bg-white bottom-0 left-0 right-0 w-full' }>
                <nav className={ 'grid grid-cols-5 pt-px text-sm font-medium text-center' } aria-label="Tabs">

                    { tabsLeft.map(tabItem) }

                    <Link className={ 'pt-[0.55rem] px-2 hover:bg-gray-50 focus:z-10' } to="#">
                        <span
                            className={ 'block bg-primary text-white inset-x-0 h-8 mt-0 w-8 h-[1.5rem] text-center mx-auto rounded' }>
                            <Add/>
                        </span>
                    </Link>

                    { tabsRight.map(tabItem) }
                </nav>
            </div>
        </> }
    </>;
};
export default NavbarBottom;