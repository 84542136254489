import React from 'react';
import BasePage from '../../layout/BasePage';
import PaymentList, { PaymentItem } from '../../lib/samfe/components/OverviewList/PaymentList';


const testList: PaymentItem[] = [
    {
        id: 5,
        name: 'Angela Beaver',
        image: 'https://images.unsplash.com/photo-1501031170107-cfd33f0cbdcc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-02'
    },
    {
        id: 6,
        name: 'Yvette Blanchard',
        image: 'https://images.unsplash.com/photo-1506980595904-70325b7fdd90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'failed',
        date: '2023-01-02'
    },
    {
        id: 7,
        name: 'Lawrence Brooks',
        image: 'https://images.unsplash.com/photo-1513910367299-bce8d8a0ebf6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-02'
    },
    // ===
    {
        id: 1,
        name: 'Leslie Abbott',
        image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-09 12:54:12'
    },
    {
        id: 2,
        name: 'Hector Adams',
        image: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-01 12:54:11'
    },
    {
        id: 3,
        name: 'Blake Alexander',
        image: 'https://images.unsplash.com/photo-1520785643438-5bf77931f493?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-01 23:54:12'
    },
    {
        id: 4,
        name: 'Fabricio Andrews',
        image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-01 01:54:12'
    },
    // ===
    {
        id: 8,
        name: 'Jeffrey Clark',
        image: 'https://images.unsplash.com/photo-1517070208541-6ddc4d3efbcb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-03'
    },
    {
        id: 9,
        name: 'Kathryn Cooper',
        image: 'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-03'
    },
    // ===
    {
        id: 19,
        name: 'Courtney Samuels',
        image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-07'
    },
    {
        id: 20,
        name: 'Tom Simpson',
        image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-07'
    },
    // ===
    {
        id: 10,
        name: 'Alicia Edwards',
        image: 'https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-04'
    },
    {
        id: 11,
        name: 'Benjamin Emerson',
        image: 'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-04'
    },
    {
        id: 12,
        name: 'Jillian Erics',
        image: 'https://images.unsplash.com/photo-1504703395950-b89145a5425b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-04'
    },
    {
        id: 13,
        name: 'Chelsea Evans',
        image: 'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-04'
    },
    // ===
    {
        id: 14,
        name: 'Michael Gillard',
        image: 'https://images.unsplash.com/photo-1519244703995-f4e0f30006d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-05'
    },
    {
        id: 15,
        name: 'Dries Giuessepe',
        image: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-05'
    },
    // ===
    {
        id: 16,
        name: 'Jenny Harrison',
        image: 'https://images.unsplash.com/photo-1507101105822-7472b28e22ac?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-06'
    },
    {
        id: 17,
        name: 'Lindsay Hatley',
        image: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-06'
    },
    {
        id: 18,
        name: 'Anna Hill',
        image: 'https://images.unsplash.com/photo-1534528741775-53994a69daeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-06'
    },
    // ===
    {
        id: 21,
        name: 'Floyd Thompson',
        image: 'https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-08'
    },
    {
        id: 22,
        name: 'Leonard Timmons',
        image: 'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'reversed',
        date: '2023-01-08'
    },
    {
        id: 23,
        name: 'Whitney Trudeau',
        image: 'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.00,
        state: 'received',
        date: '2023-01-08'
    },
    // ===
    {
        id: 24,
        name: 'Kristin Watson',
        image: 'https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'received',
        date: '2023-01-09'
    },
    {
        id: 25,
        name: 'Emily Wilson',
        image: 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'refunded',
        date: '2023-01-09'
    },
    // ===
    {
        id: 26,
        name: 'Emma Young',
        image: 'https://images.unsplash.com/photo-1505840717430-882ce147ef2d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        amount: 29.95,
        state: 'failed',
        date: '2023-01-10'
    }
];


const TestOverviewList = () => {


    return <BasePage title={ 'Stacked list' } navbarTop={ {
        showBackButton: true
    } }>
        <PaymentList items={ testList }/>
    </BasePage>;

};
export default TestOverviewList;