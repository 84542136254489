import React, { FC, JSX, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserModel } from '../../../gql/schema';
import BasePage from '../../../layout/BasePage';
import { CreateButton, DeleteButton } from '../../../lib/samfe/components/Button';
import List from '../../../lib/samfe/components/Text/List';
import useUser from '../../../resources/auth/User/useUser';


const UserListTest: FC = (): JSX.Element => {

    const user = useUser();
    const [ users, setUsers ] = useState<UserModel[]>([]);

    useEffect(() => {
        user.getList({}).then(setUsers);
    }, []);

    const handleDelete = (id: string) => {
        user.destroy({ id }).then(() => user.getList({}).then(setUsers));
    };
    return <BasePage title={ 'User List Test' } navbarTop={ {
        showBackButton: true
    } }>

        <Link to={ '/testing/user-form' }><CreateButton/></Link>
        <List items={ users.map((user, i) => <div key={ i } className={ 'grid grid-cols-6 p-4 hover:bg-primary-100 rounded' }>
            <Link className={ 'col-span-5' } to={ `/testing/user-form/${ user.id }` }>{ user.name }</Link>
            <div><DeleteButton state={ 'primary' } small={ true } onClick={ () => handleDelete(user.id) }/></div>
        </div>) }/>
    </BasePage>;
};
export default UserListTest;