import React from 'react'
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";

interface InputComponentProps {
    inputKey: string;
    // eslint-disable-next-line no-unused-vars
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    value: string;
}

const InputComponent: React.FC<InputComponentProps> = ({
    inputKey,
    handleChange,
    label,
    value,
}) => {
    return (
        <div>
            <Label htmlFor={inputKey}>{label}</Label>
            <Input
                type={'text'}
                onChange={handleChange}
                id={inputKey}
                name={inputKey}
                value={value}
                required
            />
        </div>
    )
};

export default InputComponent