import { useAuth0 } from '@auth0/auth0-react';
import { Menu } from '@headlessui/react';
import { ArrowBack } from '@mui/icons-material';
import React, { FC, JSX } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useIdToken from '../../modules/Auth0/useIdToken';
import { classNames } from '../../modules/Parse/String';
import H1 from '../Text/H1';


type MenuItem = {
    name: string
    href?: string
    action?: () => void
}


export type ExtendNavBarTopProps = {
    hideNavbar?: boolean
    showBackButton?: boolean
}
type Props = ExtendNavBarTopProps&{
    title: string
}
const NavbarTop: FC<Props> = ({
    title,
    hideNavbar = false,
    showBackButton = false
}): JSX.Element => {

    const navigate = useNavigate();

    const { logout } = useAuth0();
    const { destroyIdToken } = useIdToken();

    const menuItems: MenuItem[] = [
        {
            name: 'Profile',
            href: '#'
        },
        {
            name: 'Logout',
            action: () => logout().finally(destroyIdToken)
        }
    ];


    return <>
        { !hideNavbar && <>
            <div className={ 'bg-white border fixed w-full py-2.5 px-4 z-10 left-0 top-0' }>
                <div className="grid grid-cols-12">
                    <div className={ 'text-sm' }>{ showBackButton && <ArrowBack className={ '!text-[1.25rem] !text-neutral hover:cursor-pointer' } onClick={ () => navigate(-1) }/> }</div>
                    <div className={ 'text-center col-span-10' }>
                        <H1>{ title }</H1>
                    </div>
                    <div className={ 'text-right' }>
                        <Menu>
                            <Menu.Button className={ 'relative' }>
                                {/* @todo profile image support */ }
                                <span className={ 'rounded-full absolute bg-primary text-center text-white w-6 h-6 pt-1.5 vertical-middle text-[8px] -top-[1.15rem] right-0' }>AD</span>
                            </Menu.Button>
                            <Menu.Items className="absolute text-left right-0 z-10 mt-[0.69rem] border-l border-b w-32 origin-top-right rounded-bl bg-white shadow-sm focus:outline-none">
                                { menuItems.map((item, i) => <div key={ i } className="py-1">
                                    <Menu.Item>
                                        { ({ active }) => (
                                            item.href

                                                ?<Link to={ item.href } className={ classNames(
                                                    active ?'bg-gray-100 text-gray-900' :'text-neutral',
                                                    'block px-4 py-2 text-sm'
                                                ) }>
                                                    { item.name }
                                                </Link>

                                                :<button onClick={ item.action } className={ classNames(
                                                    active ?'bg-gray-100 text-gray-900' :'text-neutral',
                                                    'block px-4 py-2 text-sm w-full text-left'
                                                ) }>{ item.name }</button>

                                        ) }
                                    </Menu.Item>
                                </div>) }
                            </Menu.Items>
                        </Menu>
                    </div>
                </div>
            </div>
            <div className={ 'my-[2.9rem]' }></div>
        </> }
    </>;
};
export default NavbarTop;