import { Switch as HeadlessUISwitch } from '@headlessui/react';
import { Check, Close } from '@mui/icons-material';
import React, { FC, JSX, useState } from 'react';
import { classNames } from '../../../modules/Parse/String';
import FieldControl from './FieldControl';
import { BaseFieldProps } from './FieldTypes';


type Props = BaseFieldProps&{
    value?: boolean,
    // eslint-disable-next-line no-unused-vars
    onChange?: (state: boolean) => void
}

const Switch: FC<Props> = ({
    fieldControl,
    name,
    value,
    withFormik,
    onChange
}): JSX.Element => {

    const [ enabled, setEnabled ] = useState(value == true);

    const handleChange = (value: boolean) => {
        setEnabled(value);
        onChange && onChange(value);
    };

    return <FieldControl
        name={ name }
        value={ enabled }
        withFormik={ withFormik }
        formControl={ {
            ...fieldControl,
            label: {
                name: fieldControl?.label
            },
            description: {
                text: fieldControl?.description
            }
        }
        }
    >
        <HeadlessUISwitch
            checked={ enabled }
            onChange={ handleChange }
            className={ classNames(
                enabled ?'bg-primary' :'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
            ) }
        >
            <span className="sr-only">Use setting</span>
            <span
                className={ classNames(
                    enabled ?'translate-x-5' :'translate-x-0',
                    'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                ) }
            >
                <span
                    className={ classNames(
                        enabled ?'opacity-0 duration-100 ease-out' :'opacity-100 duration-200 ease-in',
                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    ) }
                    aria-hidden="true"
                >
                    <Close className={ '!text-sm stroke-danger !stroke-2' }/>

                </span>
                <span
                    className={ classNames(
                        enabled ?'opacity-100 duration-200 ease-in' :'opacity-0 duration-100 ease-out',
                        'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity'
                    ) }
                    aria-hidden="true"
                >
                    <Check className={ '!text-sm stroke-primary !stroke-2' }/>
                </span>
            </span>
        </HeadlessUISwitch>
    </FieldControl>;
};
export default Switch;