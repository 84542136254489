import React, { FC, JSX } from 'react';
import { classNames } from '../../../modules/Parse/String';


export type ExtendLabelProps = {
    name?: string
}

type Props = Omit<JSX.IntrinsicElements['label'], 'children'>&ExtendLabelProps

const Label: FC<Props> = ({
    className,
    name,
    ...props
}): JSX.Element => <label { ...props }
    className={ classNames('text-primary font-bold pb-1 block', className) }
>
    { name }
</label>;
export default Label;