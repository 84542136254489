import React, {useState} from "react";
import {
    Form,
    FormItem,
} from "@/components/ui/form"
import {UseFormReturn} from "react-hook-form";
import {z} from "zod";
import {formSchema} from "../../CheckOutPage/CheckOutPage";
import {Button} from "@/components/ui/button";
import InputSameLineComponent from "./InputSameLineComponent";
import InputRadioComponent from "./InputRadioComponent";
import InputCheckOutComponent from "./InputCheckOutComponent";
import InputCheckboxComponent from "./InputCheckboxComponent";
import SEPAFormComponent from "./SEPAFormComponent";
import PayPalFormComponent from "./PayPalFormComponent";


interface CheckOutFormComponentProps {
    form: UseFormReturn<z.infer<typeof formSchema>>;
    labels: string[];
    paymentMethods: string[];
    // eslint-disable-next-line no-unused-vars
    handleOnSubmit: (values: z.infer<typeof formSchema>) => void;
    buttonText: string;
}

export type FormSchemaKeys = keyof typeof formSchema['_def']['shape'];

const CheckOutFormComponent: React.FC<CheckOutFormComponentProps> = ({
    form,
    paymentMethods,
    handleOnSubmit,
    buttonText
}) => {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Credit Card')

    /* temp solution */
    const formSchemaProperty: FormSchemaKeys[] = Object.keys(formSchema.shape) as FormSchemaKeys[]

    /* */

    function handleOnError(error: Object) {
        console.log(error)
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(handleOnSubmit, handleOnError)}
                className={'w-full flex flex-col flex-wrap space-y-5'}>
                <InputRadioComponent form={form} options={paymentMethods} formName={formSchemaProperty[0]}
                    selectedPaymentMethod={selectedPaymentMethod}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                    label={'Payment Method'}/>
                <>
                    {
                        selectedPaymentMethod === 'Credit Card' ?
                            <FormItem className={'flex flex-col space-y-0 gap-y-2 items-center flex-wrap'}>
                                {
                                    ['Card Number', 'Card Holder'].map((label, index) => {
                                        return <InputCheckOutComponent key={index} form={form} label={label}
                                            formName={formSchemaProperty[index + 1]}/>
                                    })
                                }
                                <InputSameLineComponent form={form} formName1={formSchemaProperty[3]}
                                    formName2={formSchemaProperty[4]}
                                    label1={'Expiration Date'}
                                    label2={'CVV'}
                                    containerClass={'flex gap-x-2 items-center space-y-0'}
                                    firstInputClass={'flex-grow'} secondInputClass={'w-3/12'}/>
                            </FormItem>
                            : selectedPaymentMethod === 'SEPA' ?
                                <SEPAFormComponent/>
                                : <PayPalFormComponent/>
                    }
                </>
                <InputCheckboxComponent form={form} formName={formSchemaProperty[5]} message={'Accept terms and' +
                    ' conditions'}/>

                <Button type={'submit'} className={'text-white'}>{buttonText}</Button>
            </form>
        </Form>
    )
};
export default CheckOutFormComponent