import React from 'react';
import {Tabs, TabsList, TabsTrigger} from "@/components/ui/tabs";


interface PricingTabComponentProps {
    // eslint-disable-next-line no-unused-vars
    handleClick: (value: string) => void;
    value: string[]
    defaultValue: string;
    currentTab: string;

}

const PricingTabComponent: React.FC<PricingTabComponentProps> = ({handleClick, value, currentTab, defaultValue}) => {

    return (
        <Tabs defaultValue={defaultValue} className="my-10" value={currentTab} onValueChange={handleClick}>
            <TabsList className={'grid grid-cols-3 w-full gap-x-5'}>
                {value.map((value, index) => {
                    return <TabsTrigger value={value} className={'text-lg'} key={index}>{value}</TabsTrigger>
                })}
            </TabsList>
        </Tabs>

    );
};

export default PricingTabComponent;
