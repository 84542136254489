import React, { FC, JSX, MouseEventHandler, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';


type Props = {
    onClick: MouseEventHandler<HTMLButtonElement>;
};


const LoginButton: FC<Props> = ({ onClick }): JSX.Element => {

    const [ cookies, setCookie, deleteCookie ] = useCookies();
    const [ loginId ] = useState((new Date()).getTime());


    const iosCookieName = 'tjecko_ios';
    const [ iosLogin, setIosLogin ] = useState(false);

    /**
     * @todo Remove or make useful, used for "ios_mock" change observation.
     */
    useEffect(() => {
        if (cookies['tjecko_ios_login'] && cookies['tjecko_ios_login'] == loginId) {
            setIosLogin(true);
        }
    }, [ cookies ]);


    /**
     * Redirect to Auth0 or let Swift handle the authentication.
     * @param e
     */
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (cookies[iosCookieName]) {
            setCookie('tjecko_ios_login', loginId);
            return;
        }
        deleteCookie(iosCookieName);
        onClick(e);
    };

    return (
        <button onClick={ handleClick } className="transition hover:bg-primary-500 active:bg-primary-700 w-full text-white bg-primary py-2 font-semibold rounded mt-10">
            Sign In { iosLogin && ' * signing in with ios' }
        </button>
    );
};

export default LoginButton;
