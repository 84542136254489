import React, { ChangeEvent, FC, HTMLInputTypeAttribute, JSX, useEffect, useState } from 'react';
import { classNames } from '../../../modules/Parse/String';
import FieldControl from './FieldControl';
import { BaseFieldProps } from './FieldTypes';


type Props = JSX.IntrinsicElements['input']&BaseFieldProps&{
    type: Omit<HTMLInputTypeAttribute, 'checkbox'|'button'|'submit'>,
    value?: string|number
}

const Input: FC<Props> = ({
    className,
    fieldControl,
    name,
    onChange,
    required,
    withFormik,
    ...props
}): JSX.Element => {

    const [ value, setValue ] = useState(props.value);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        setValue(e.target.value);
        onChange && onChange(e);
    };

    useEffect(() => {
        setValue(value);
        console.log({ 'props.value': props.value });
    }, [ props.value ]);

    return <FieldControl
        value={ value }
        name={ name }
        withFormik={ withFormik }
        formControl={ {
            ...fieldControl,
            label: {
                name: fieldControl?.label
            },
            description: {
                text: fieldControl?.description
            }
        } }
    >
        <input
            { ...props }
            id={ name }
            value={ value }
            onChange={ handleChange }
            required={ withFormik ?false :required }
            className={ classNames('w-full py-2 font-[400] px-4 bg-primary-100 text-primary rounded focus:outline-none', className) }
        />
    </FieldControl>;
};
export default Input;