import React, { FC, JSX } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BaseCompanyFragment, UpdateCompanyInput, UpdateCompanyMutationVariables } from '../../../gql/schema';
import BasePage from '../../../layout/BasePage';
import Form, { useForm } from '../../../lib/samfe/modules/Form';
import useSchema from '../../../lib/samfe/modules/Form/useSchema';
import Yup from '../../../lib/samfe/modules/Form/Yup';
import useCompany from '../../../resources/Company/useCompany';


let i = 100;
const CompanyFormUpdateTest: FC = (): JSX.Element => {
    console.log({ i: i++ });

    const navigate = useNavigate();
    const company = useCompany();
    const { id } = useParams();

    const { validationSchema } = useSchema<UpdateCompanyInput>({
        name: Yup.string()
            .controlType('input')
            .fieldConfig({
                label: 'Naam'
            })
            .inputType('text')
            .required()
    });

    const form = useForm<
        UpdateCompanyInput,
        UpdateCompanyMutationVariables,
        BaseCompanyFragment
    >({
        config: {
            id
        },
        http: {
            initialModel: () => company.getSingle({ id: id! }),
            formAction: company.update,
            method: 'PUT',
            onSuccess: () => {
                navigate('/testing/company-list');
            }
        },
        validationSchema

    });


    return <BasePage title={ 'Company Update Test Form' } navbarTop={ {
        showBackButton: true
    } }>
        <Form { ...form } withSaveButton={ true }/>
    </BasePage>;
};
export default CompanyFormUpdateTest;