import React, { FC, JSX } from 'react';
import { classNames } from '../../modules/Parse/String';
import { HeadingType } from './TextTypes';


const H3: FC<HeadingType> = ({ children, ...props }): JSX.Element => <h3
    { ...props }
    className={ classNames('font-[600] text-sm text-neutral my-2', props.className) }
>{ children }</h3>;

export default H3;