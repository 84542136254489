import React, { FC, JSX } from 'react';
import { Link } from 'react-router-dom';
import BasePage from '../../layout/BasePage';
import H2 from '../../lib/samfe/components/Text/H2';
import List from '../../lib/samfe/components/Text/List';


const Testing: FC = (): JSX.Element => {

    return <BasePage title={ 'Testing' } navbarTop={ {
        showBackButton: true
    } }>
        <H2>User</H2>
        <List state={ 'bullet' } items={ [
            <Link key={ 0 } to={ 'user-form' }>User post form</Link>,
            <Link key={ 0 } to={ 'user-form/:id' }>User update form</Link>,
            <Link key={ 0 } to={ 'user-list' }>User list</Link>
        ] }/>

        <H2>Company</H2>
        <List state={ 'bullet' } items={ [
            <Link key={ 0 } to={ 'company-form' }>Company post form</Link>,
            <Link key={ 0 } to={ 'company-form/:id' }>Company update form</Link>,
            <Link key={ 0 } to={ 'company-list' }>Company list</Link>,
            <Link key={ 0 } to={ 'onboarding' }>Merchant onboarding</Link>
        ] }/>
    </BasePage>;
};
export default Testing;