import { AdsClick } from '@mui/icons-material';
import React, { FC, JSX } from 'react';
import BasePage from '../../layout/BasePage';
import { ActionButton, CancelButton, CreateButton, DeleteButton, EditButton, SaveButton } from '../../lib/samfe/components/Button';
import AcceptButton from '../../lib/samfe/components/Button/AcceptButton';
import DeclineButton from '../../lib/samfe/components/Button/DeclineButton';
import LoginButton from '../../lib/samfe/components/Button/LoginButton';


const Buttons: FC = (): JSX.Element => {
    return <BasePage title={ 'Buttons' } navbarTop={ {
        showBackButton: true
    } }>

        <div className={ 'grid gap-4 grid-cols-1' }>
            <div className="grid gap-2 grid-cols-3">
                <CreateButton/>
                <CreateButton state={ 'secondary' }/>
                <CreateButton state={ 'tertiary' }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <CreateButton disabled={ true }/>
                <CreateButton state={ 'secondary' } disabled={ true }/>
                <CreateButton state={ 'tertiary' } disabled={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <CreateButton small={ true }/>
                <CreateButton state={ 'secondary' } small={ true }/>
                <CreateButton state={ 'tertiary' } small={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <CreateButton small={ true } disabled={ true }/>
                <CreateButton state={ 'secondary' } small={ true } disabled={ true }/>
                <CreateButton state={ 'tertiary' } small={ true } disabled={ true }/>
            </div>
        </div>

        <hr className={ 'my-8' }/>

        <div className={ 'grid gap-4 grid-cols-1' }>
            <div className="grid gap-2 grid-cols-3">
                <CancelButton/>
                <CancelButton state={ 'secondary' }/>
                <CancelButton state={ 'tertiary' }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <CancelButton disabled={ true }/>
                <CancelButton state={ 'secondary' } disabled={ true }/>
                <CancelButton state={ 'tertiary' } disabled={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <CancelButton small={ true }/>
                <CancelButton state={ 'secondary' } small={ true }/>
                <CancelButton state={ 'tertiary' } small={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <CancelButton small={ true } disabled={ true }/>
                <CancelButton state={ 'secondary' } small={ true } disabled={ true }/>
                <CancelButton state={ 'tertiary' } small={ true } disabled={ true }/>
            </div>
        </div>

        <hr className={ 'my-8' }/>

        <div className={ 'grid gap-4 grid-cols-1' }>
            <div className="grid gap-2 grid-cols-3">
                <EditButton/>
                <EditButton state={ 'secondary' }/>
                <EditButton state={ 'tertiary' }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <EditButton disabled={ true }/>
                <EditButton state={ 'secondary' } disabled={ true }/>
                <EditButton state={ 'tertiary' } disabled={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <EditButton small={ true }/>
                <EditButton state={ 'secondary' } small={ true }/>
                <EditButton state={ 'tertiary' } small={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <EditButton small={ true } disabled={ true }/>
                <EditButton state={ 'secondary' } small={ true } disabled={ true }/>
                <EditButton state={ 'tertiary' } small={ true } disabled={ true }/>
            </div>
        </div>

        <hr className={ 'my-8' }/>

        <div className={ 'grid gap-4 grid-cols-1' }>
            <div className="grid gap-2 grid-cols-3">
                <SaveButton/>
                <SaveButton state={ 'secondary' }/>
                <SaveButton state={ 'tertiary' }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <SaveButton disabled={ true }/>
                <SaveButton state={ 'secondary' } disabled={ true }/>
                <SaveButton state={ 'tertiary' } disabled={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <SaveButton small={ true }/>
                <SaveButton state={ 'secondary' } small={ true }/>
                <SaveButton state={ 'tertiary' } small={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <SaveButton small={ true } disabled={ true }/>
                <SaveButton state={ 'secondary' } small={ true } disabled={ true }/>
                <SaveButton state={ 'tertiary' } small={ true } disabled={ true }/>
            </div>
        </div>

        <hr className={ 'my-8' }/>

        <div className={ 'grid gap-4 grid-cols-1' }>
            <div className="grid gap-2 grid-cols-3">
                <DeleteButton/>
                <DeleteButton state={ 'secondary' }/>
                <DeleteButton state={ 'tertiary' }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <DeleteButton disabled={ true }/>
                <DeleteButton state={ 'secondary' } disabled={ true }/>
                <DeleteButton state={ 'tertiary' } disabled={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <DeleteButton small={ true }/>
                <DeleteButton state={ 'secondary' } small={ true }/>
                <DeleteButton state={ 'tertiary' } small={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <DeleteButton small={ true } disabled={ true }/>
                <DeleteButton state={ 'secondary' } small={ true } disabled={ true }/>
                <DeleteButton state={ 'tertiary' } small={ true } disabled={ true }/>
            </div>
        </div>

        <hr className={ 'my-8' }/>

        <div className={ 'grid gap-4 grid-cols-1' }>
            <div className="grid gap-2 grid-cols-3">
                <AcceptButton/>
                <AcceptButton state={ 'secondary' }/>
                <AcceptButton state={ 'tertiary' }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <AcceptButton disabled={ true }/>
                <AcceptButton state={ 'secondary' } disabled={ true }/>
                <AcceptButton state={ 'tertiary' } disabled={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <AcceptButton small={ true }/>
                <AcceptButton state={ 'secondary' } small={ true }/>
                <AcceptButton state={ 'tertiary' } small={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <AcceptButton small={ true } disabled={ true }/>
                <AcceptButton state={ 'secondary' } small={ true } disabled={ true }/>
                <AcceptButton state={ 'tertiary' } small={ true } disabled={ true }/>
            </div>
        </div>

        <hr className={ 'my-8' }/>

        <div className={ 'grid gap-4 grid-cols-1' }>
            <div className="grid gap-2 grid-cols-3">
                <DeclineButton/>
                <DeclineButton state={ 'secondary' }/>
                <DeclineButton state={ 'tertiary' }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <DeclineButton disabled={ true }/>
                <DeclineButton state={ 'secondary' } disabled={ true }/>
                <DeclineButton state={ 'tertiary' } disabled={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <DeclineButton small={ true }/>
                <DeclineButton state={ 'secondary' } small={ true }/>
                <DeclineButton state={ 'tertiary' } small={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <DeclineButton small={ true } disabled={ true }/>
                <DeclineButton state={ 'secondary' } small={ true } disabled={ true }/>
                <DeclineButton state={ 'tertiary' } small={ true } disabled={ true }/>
            </div>
        </div>

        <hr className={ 'my-8' }/>

        <div className={ 'grid gap-4 grid-cols-1' }>
            <div className="grid gap-2 grid-cols-3">
                <ActionButton Icon={ AdsClick }>Action</ActionButton>
                <ActionButton Icon={ AdsClick } state={ 'secondary' }>Action</ActionButton>
                <ActionButton Icon={ AdsClick } state={ 'tertiary' }>Action</ActionButton>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <ActionButton Icon={ AdsClick } disabled={ true }>Action</ActionButton>
                <ActionButton Icon={ AdsClick } state={ 'secondary' } disabled={ true }>Action</ActionButton>
                <ActionButton Icon={ AdsClick } state={ 'tertiary' } disabled={ true }>Action</ActionButton>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <ActionButton Icon={ AdsClick } small={ true }>Action</ActionButton>
                <ActionButton Icon={ AdsClick } state={ 'secondary' } small={ true }>Action</ActionButton>
                <ActionButton Icon={ AdsClick } state={ 'tertiary' } small={ true }/>
            </div>
            <div className="grid gap-2 grid-cols-3">
                <ActionButton Icon={ AdsClick } small={ true } disabled={ true }>Action</ActionButton>
                <ActionButton Icon={ AdsClick } state={ 'secondary' } small={ true } disabled={ true }>Action</ActionButton>
                <ActionButton Icon={ AdsClick } state={ 'tertiary' } small={ true } disabled={ true }>Action</ActionButton>
            </div>
        </div>

        <hr className={ 'my-8' }/>

        <LoginButton onClick={ () => {
        } }/>

    </BasePage>;
};
export default Buttons;