import { HashKey, HashMap } from '../../Samfe';
import { SortByDate } from './Date';


/**
 * Check if two objects are the same.
 *
 * @param prevState
 * @param newState
 */
export const ObjectIsChanged = (prevState: object, newState: object): boolean => {
    const orderedObject = (obj: { [k: string]: any }) => Object.keys(obj).sort().reduce((prev, key) => ({
        ...prev, [key]: obj[key]
    }), {});
    return JSON.stringify(orderedObject(prevState)) != JSON.stringify(orderedObject(newState));
};

/**
 * Object key type for sorting
 */
type KeyType = 'string'|'number'|'date';

/**
 * Sort object by its keys.
 *
 * Supported date formats: "Y-m-d" | "Y-m-d H:i:s"
 *
 * @example keyType = 'string', direction = 'ASC' -> 'Albert', 'Bernard', ...
 * @example keyType = 'number', direction = 'DESC' -> 3, 2, 1, ...
 * @example keyType = 'date', direction = 'ASC' -> '2023-01-01', '2023-01-02', ...
 * @example keyType = 'date', direction = 'DESC' -> '2023-01-01 15:00:00', '2023-01-01 03:00:00', ...
 *
 * @param {T extends object} obj
 * @param {KeyType} keyType
 * @param {'ASC'|'DESC'} direction
 * @constructor
 */
export const SortObjectKeys = <T extends object>(
    obj: T,
    keyType: KeyType,
    direction: 'ASC'|'DESC' = 'ASC'
): T => {
    const castedObject = obj as HashMap<HashKey, any>;
    return Object.keys(castedObject)
        .sort((a, b) => {
            const first = direction == 'ASC' ?a :b;
            const second = direction == 'ASC' ?b :a;

            if (keyType == 'number') {return a<b ?1 :-1;}
            return keyType == 'date'
                // @todo determine if SortByDate ASC/DESC is set properly
                ?SortByDate(direction == 'ASC' ?second :first, direction == 'ASC' ?first :second, direction)
                :first.localeCompare(second);
        })
        .reduce((prev, key) => ({ ...prev, [key]: castedObject[key as HashKey] }), {}) as T;
};