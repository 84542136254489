import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Yup from './Yup';


export type Shape<Dto> = Record<keyof Dto, Yup.Schema>|(() => Record<keyof Dto, Yup.Schema>);
export type Schema<Dto extends object> = Yup.ObjectSchema<Partial<Dto>, Partial<Dto>, Partial<Dto>>;


function useSchema<Dto extends Partial<object>>(initialShape: Shape<Dto>): { validationSchema: Schema<Dto>, setShape: Dispatch<SetStateAction<Shape<Dto>>> } {

    const [ shape, setShape ] = useState<Shape<Dto>>(initialShape);

    const schemaFromShape = (shape: Shape<Dto>): Schema<Dto> => Yup.object<Dto>().shape(typeof shape === 'function'
        ?shape()
        :shape
    ) as unknown as Schema<Dto>;

    const [ validationSchema, setSchema ] = useState<Schema<Dto>>(schemaFromShape(initialShape));

    useEffect(() => {
        setSchema(schemaFromShape(shape));
    }, [ shape ]);

    return { validationSchema, setShape };
}


export default useSchema;
