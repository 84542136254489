/* eslint-disable no-unused-vars */

import React from 'react';
import * as Yup from 'yup';
import { BooleanSchema, NumberSchema, setLocale, StringSchema } from 'yup';
import { nl } from 'yup-locales';
import { BaseFieldProps, FieldControlType } from '../../components/Form/Field/FieldTypes';
import { SelectConfig } from '../../components/Form/Field/Select';
import { FormControlType } from './FormControl';


/** Localization */
setLocale(nl);


/**
 * Declare custom properties for Yup in order to manage form control.
 */
declare module 'yup' {

    interface BaseSchema<T> {
        controlType(controlType: FormControlType): T;


        inputType(inputType: React.HTMLInputTypeAttribute): T;


        fieldConfig(config: FieldControlType): T;


        selectConfig<Model extends object>(config: SelectConfig<Model>): T;


        hidden(isHidden: boolean): T;


        disabled(isDisabled: boolean): T;
    }

    interface StringSchema extends BaseSchema<StringSchema> {
        initialValue(value: string): StringSchema;


        onChange(callBack: (value: string) => void): StringSchema;
    }

    interface BooleanSchema extends BaseSchema<BooleanSchema> {
        initialValue(value: boolean): BooleanSchema;


        onChange(callBack: (value: boolean) => void): BooleanSchema;
    }

    interface NumberSchema extends BaseSchema<NumberSchema> {
        initialValue(value?: number): NumberSchema;


        onChange(callBack: (value: number) => void): NumberSchema;


        steps(stepSize: number): NumberSchema;
    }
}


/**
 * Add custom properties to spec.meta.
 *
 * @param schema
 * @param propertyName
 */
function setMeta<T>(schema: any, propertyName: string): void {
    Yup.addMethod(schema, propertyName, function(this: Yup.Schema, value: T): Yup.Schema {
        return this.test(
            propertyName,
            `Invalid value:${ value } for property:${ propertyName } given.`,
            () => true
        ).meta({ [`${ propertyName }`]: value });
    });
}


/**
 * Global types.
 *
 * Add custom meta methods so that form fields can be created from schema.
 */
[ Yup.string, Yup.number, Yup.boolean ].forEach(schemaType => {
    setMeta<FormControlType>(schemaType, 'controlType');
    setMeta<React.HTMLInputTypeAttribute>(schemaType, 'inputType');
    setMeta<BaseFieldProps|undefined>(schemaType, 'fieldConfig');
    setMeta<any|undefined>(schemaType, 'selectConfig');
    setMeta<boolean|undefined>(schemaType, 'hidden');
    setMeta<boolean|undefined>(schemaType, 'disabled');
});


/**
 * String types
 */
[ Yup.string ].forEach(schemaType => {
    setMeta<string|undefined>(schemaType, 'initialValue');
    setMeta<((value: string) => void)|undefined>(schemaType, 'onChange');
});


/**
 * Boolean types
 */
[ Yup.boolean ].forEach(schemaType => {
    setMeta<boolean|undefined>(schemaType, 'initialValue');
    setMeta<((value: boolean) => void)|undefined>(schemaType, 'onChange');
});


/**
 * Number types.
 */
[ Yup.number ].forEach(schemaType => {
    setMeta<number|undefined>(schemaType, 'initialValue');
    setMeta<((value: number) => void)|undefined>(schemaType, 'onChange');
    setMeta<number|undefined>(schemaType, 'steps');
});

export default Yup;