import { Field, useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';


/**
 * Handle Formik field in background.
 *
 * @param withFormik
 * @param name
 * @param value
 */
const useFormikField = (withFormik: boolean|undefined, name: string, value?: string|number|boolean) => {

    const [ formikValue, setFormikValue ] = useState<string|number|boolean|undefined>(value !== undefined ?value :'');
    const [ field, meta ] = withFormik ?useField(name) :[ {}, {} ];
    const formikCtx = useFormikContext();

    useEffect(() => {
        if (!withFormik) {
            return;
        }

        formikCtx.setTouched({ [name]: true }).then(() => {
            formikCtx.setFieldValue(name, value).then();
        });
    }, [ value ]);

    return {
        formikField: <Field className={ 'hidden' } { ...field } name={ name } value={ formikValue }/>,
        formikError: meta.touched ?meta.error :undefined,
        setFormikValue
    };
};
export default useFormikField;