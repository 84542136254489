import { SvgIconComponent } from '@mui/icons-material';
import React, { FC, JSX, ReactNode } from 'react';
import Button, { ButtonProps } from './Button';


/**
 *
 * @param props
 * @constructor
 */
const ActionButton: FC<ButtonProps&{ Icon: SvgIconComponent, children?: ReactNode }> = (props): JSX.Element => (
    <Button { ...props } textColor={ 'white' } bgColor={ 'primary' }>
        { props.children }
    </Button>
);
export default ActionButton;